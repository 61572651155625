import React, { useState, useEffect } from 'react';
import Component from '../../components/Component'
import { BASE_URL, handleDynamicRequest } from '../../components/CommonExport';
import { TextField, Grid, Autocomplete, Button, IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'; // Import the ArrowForward icon
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setMatches } from '../../Store/Match/action';

const formatDateRange = (startDate, endDate) => {
    const formatDate = (date) => {
        const d = new Date(date);
        const day = d.getDate();
        const month = d.toLocaleString('default', { month: 'short' });
        const year = d.getFullYear().toString().slice(-2);

        let daySuffix;
        switch (day) {
            case 1:
            case 21:
            case 31:
                daySuffix = 'st';
                break;
            case 2:
            case 22:
                daySuffix = 'nd';
                break;
            case 3:
            case 23:
                daySuffix = 'rd';
                break;
            default:
                daySuffix = 'th';
        }

        return `${day}${daySuffix} ${month} ${year}`;
    };

    return `${formatDate(startDate)} - ${formatDate(endDate)}`;
};

function MatchePanel() {
    const [matchData, setMatchData] = useState([]);
    const [searchText, setSearchText] = React.useState('');
    const [filteredData, setFilteredData] = React.useState(matchData);

    const [dateFromFilter, setDateFromFilter] = useState('');
    const [dateToFilter, setDateToFilter] = useState('');
    const [matchFilter, setMatchFilter] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    console.log(matchFilter?.competition__cid)

    useEffect(() => {
        const requestBody = {};
        if (dateFromFilter) {
            requestBody.startDate = dateFromFilter;
        }
        if (dateToFilter) {
            requestBody.endDate = dateToFilter;
        }

        if (matchFilter && matchFilter.id !== 'all') {
            requestBody.competition__cid = matchFilter.competition__cid;
        }

        fetch(`${BASE_URL}/tracking/competitions`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMTJiNTBhNDgtNWU5ZS00MzNmLTgyNmMtMTJhNmQ3MmM5N2NkIiwiaWF0IjoxNzExNDU2OTEzLCJleHAiOjE3NDI1NjA5MTMsInBybSI6IjMwZDQyNzg2ZGMyNTU3MDBkZDkzNzViMWQ4ZDYwN2E2MzBhYzViMzJhNWU1MjUxODQ2ZDQyNzE1YjBlN2Y3YTllZjczZjAzZDk1MzI3OGQ5NTdjOTVmOGE2YTVkZjk1MTM3MDc4ZTY4ODNlNTU3MjUzODczZjViYjNjMDZiNzZmIn0.msUlenclhnnuqwdU9R5HL32rYnUVmdyITkPlzSWDcio'

            },
            body: JSON.stringify(requestBody)
        })
            .then(response => response.json())
            .then(data => {
                setMatchData(data.data.users);
                setFilteredData(data.data.users);
            })
            .catch(error => console.error('Error fetching table data:', error));
    }, [dateFromFilter, dateToFilter, matchFilter]);

    const columns = [
        {
            field: 'date_start',
            headerName: 'Date',
            flex: 1,
            renderCell: (params) => {
                const { date_start, date_end } = params.row;
                return formatDateRange(date_start, date_end);
            }
        },
        { field: 'competition__title', headerName: 'Competitions', flex: 1 },
        { field: 'title', headerName: 'Match', flex: 1 },
        {
            field: 'cta',
            headerName: 'CTA',
            flex: 1,
            renderCell: (params) => (
                <IconButton className='back-btn' style={{ background: '#FFFFFF' }} >
                    <ArrowForwardIcon
                        style={{ cursor: 'pointer', color: '#FFFFFF' }}
                        onClick={() => handleSelect(params.row)}
                    />
                </IconButton>


            )
        },
    ];

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchText(value);
        const filteredRows = matchData.filter((row) => {
            return Object.values(row).some((field) =>
                String(field).toLowerCase().includes(value)
            );
        });
        setFilteredData(filteredRows);
    };

    const handleSelect = (row) => {
        dispatch(setMatches(row));
        navigate('/match-details');
    };
    const getUniqueOptions = (data) => {
        const seen = new Set();
        return data.filter(item => {
            const isDuplicate = seen.has(item.competition__title);
            seen.add(item.competition__title);
            return !isDuplicate;
        });
    };

    const uniqueMatchData = getUniqueOptions(matchData);
    const options = [{ id: 'all', name: 'All' }, ...uniqueMatchData];

    return (


        <Component>
            <div class="page-body">
                <div class="container-fluid">
                    <div class="page-header">
                        <div class="row">
                            <div className="col-sm-12">
                                <div className="d-flex align-items-center ">
                                    <IconButton style={{ background: '#FFFFFF' }} component={Link} to="/dashboards">
                                        <ArrowBackIcon />
                                    </IconButton>
                                    <h3 style={{ marginLeft: '20px' }}>Match Panel</h3>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="container-fluid">
                    <div class="row">


                        <div class="col-sm-12">
                            <div class="card">
                                <div class="card-body">
                                    <Grid container spacing={2} sx={{ marginBottom: '20px' }}>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <TextField
                                                label="From Date"
                                                type="date"
                                                variant="outlined"
                                                fullWidth
                                                value={dateFromFilter}
                                                onChange={(e) => setDateFromFilter(e.target.value)}
                                                InputLabelProps={{ shrink: true }}
                                                sx={{ backgroundColor: 'white', borderRadius: '8px' }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <TextField
                                                label="To Date"
                                                type="date"
                                                variant="outlined"
                                                fullWidth
                                                value={dateToFilter}
                                                onChange={(e) => setDateToFilter(e.target.value)}
                                                InputLabelProps={{ shrink: true }}
                                                sx={{ backgroundColor: 'white', borderRadius: '8px' }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <Autocomplete
                                                id="match-filter"
                                                options={options}
                                                getOptionLabel={(option) => option.competition__title || option.name}
                                                value={matchFilter}
                                                onChange={(event, newValue) => {
                                                    setMatchFilter(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Select Competition" variant="outlined" fullWidth />}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Search"
                                                variant="outlined"
                                                value={searchText}
                                                onChange={handleSearch}
                                            />
                                        </Grid>
                                    </Grid>

                                    <DataGrid
                                        rows={filteredData}
                                        columns={columns}
                                        pageSize={5}
                                        rowsPerPageOptions={[5]}
                                        getRowId={(row) => row?.id}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </Component>

    )
}

export default MatchePanel
