import React, { useEffect, useState, useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, TextField, IconButton } from '@mui/material';
import { CloudUpload as CloudUploadIcon, Save as SaveIcon, Close as CloseIcon } from '@mui/icons-material';
import axios from 'axios';
import AWS from 'aws-sdk';
import Component from '../../components/Component.js';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../components/AppConfig.js';

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
const REGION = process.env.REACT_APP_REGION;
const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;
const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;

const IconsPage = () => {
    const [icons, setIcons] = useState([]);
    const [loading, setLoading] = useState(false);
    const [s3Url, setS3Url] = useState('');
    const [title, setTitle] = useState('');
    const fileInputRef = useRef(null);

    // Fetch icons from the backend
    const fetchIcons = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/icons`);
            setIcons(response.data.data.icons || []);
        } catch (error) {
            console.error('Error fetching icons:', error);
        }
    };

    useEffect(() => {
        fetchIcons();
    }, []);

    // Handle file upload to S3
    const handleFileUpload = async (event) => {
        console.log('running....')
        const s3 = new AWS.S3({
            accessKeyId: ACCESS_KEY,
            secretAccessKey: SECRET_ACCESS_KEY,
            region: REGION,
        });
        const file = event.target.files[0];
        console.log('file',file)
        if (!file) return;

        setLoading(true);
        const fileNameParts = file.name.split('.');
        const fileExtension = fileNameParts.length > 1 ? `.${fileNameParts.pop()}` : '';
        const fileNameWithoutExtension = fileNameParts.join('.');
        const params = {
            Bucket: S3_BUCKET,
            Key: `icons/${fileNameWithoutExtension}_${Date.now()}${fileExtension}`,
            Body: file,
            ContentType: file.type,
        };

        try {
            const uploadResponse = await s3.upload(params).promise();
            console.log(uploadResponse)
            setS3Url(uploadResponse.Location);
        } catch (error) {
            console.error('Error uploading file:', error);
        } finally {
            setLoading(false);
        }
    };
    const deleteIcon = async () => {
        if (!s3Url) return;

        const s3 = new AWS.S3({
            accessKeyId: ACCESS_KEY,
            secretAccessKey: SECRET_ACCESS_KEY,
            region: REGION,
        });

        const fileName = s3Url.split('/').pop();
        const params = {
            Bucket: S3_BUCKET,
            Key: `icons/${fileName}`,
        };

        try {
            const deleteResponse = await s3.deleteObject(params).promise();
            console.log(deleteResponse)
            setS3Url('');
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
              }
        } catch (error) {
            console.error('Error deleting icon:', error);
        }
    };

    // Save the icon data to the database
    const handleSaveToDatabase = async () => {
        if (!s3Url || !title) {
            alert('Please provide both a title and upload an image.');
            return;
        }

        setLoading(true);
        try {
            await axios.post(`${BASE_URL}/icons`, { logo_url: s3Url, title });
            fetchIcons(); 
            setS3Url(''); 
            setTitle(''); 
        } catch (error) {
            console.error('Error saving to database:', error);
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        { field: 'title', headerName: 'Title', width: 150 },
        {
            field: 'url',
            headerName: 'Icon',
            width: 150,
            renderCell: (params) => {
            console.log(params.row.logo_url)
            return (
                <img src={params.row.logo_url} alt="icon" style={{ width: '50px', height: '50px' }} />
            )},
        },
    ];

    return (
        <Component>
            <div className="page-body">
                <div className="container-fluid">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="d-flex align-items-center">
                                    <IconButton style={{ background: '#FFFFFF' }} component={Link} to="/">
                                        <ArrowBackIcon />
                                    </IconButton>
                                    <h3 style={{ marginLeft: '20px' }}>Icons</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <div style={{ height: 400, width: '100%' }}>

                                        <TextField
                                            label="Title"
                                            variant="outlined"
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                            style={{ marginBottom: 16 }}
                                        />

                                        <input
                                            accept="image/*"
                                            id="upload-icon"
                                            type="file"
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                            onChange={handleFileUpload}
                                        />

                                        <label htmlFor="upload-icon" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                component="span"
                                                startIcon={<CloudUploadIcon />}
                                                disabled={loading}
                                            >
                                                Upload to S3
                                            </Button>
                                            {s3Url && (
                                                <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                                                    <img
                                                        src={s3Url}
                                                        alt="uploaded icon preview"
                                                        style={{ width: '50px', height: '50px', borderRadius: '4px' }}
                                                    />
                                                    <IconButton
                                                        size="very-small"
                                                        onClick={deleteIcon}
                                                        style={{ position: 'absolute', top: 0, right: 0, backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
                                                    >
                                                        <CloseIcon fontSize="small" />
                                                    </IconButton>
                                                </div>
                                            )}
                                        </label>

                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            startIcon={<SaveIcon />}
                                            onClick={handleSaveToDatabase}
                                            disabled={loading || !s3Url || !title}
                                            style={{ marginBottom: 16 }}
                                        >
                                            Save to Database
                                        </Button>

                                        <DataGrid
                                            rows={icons}
                                            columns={columns}
                                            pageSize={5}
                                            rowsPerPageOptions={[5, 10, 20]}
                                            loading={loading}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Component>

    );
};

export default IconsPage;
