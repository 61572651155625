import { useState, useEffect } from 'react';
function ToggleButton() {
    const [isOn, setIsOn] = useState(false);
    useEffect(() => {
      const env = localStorage.getItem('env') == 'true' ? true : false || false;
      setIsOn(env);
    }, []);
    const handleToggle = () => {
      localStorage.setItem('env', !isOn);
      setIsOn(!isOn);
      window.location.reload();
    };
  
    return (
      <button onClick={handleToggle} className={isOn ? 'btn-on' : 'btn-off'}>
        {isOn ? 'Prod' :'Dev'}
      </button>
    );
  }

export default ToggleButton;