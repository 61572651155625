import React, { useState, useRef, useEffect } from 'react';
import { BASE_URL } from '../../components/AppConfig';
import SetAlert from '../../components/SetAlert';
import { useDispatch } from 'react-redux';
import { handleDynamicRequest } from '../../components/DyanamicRequest';
import { setUserId, setUserRole, setUserName, setJwtToken, setU_photo, setPermissions, setIsSuperAdmin } from '../../Store/Role/action';
import NavBar from '../../components/NavBar/NavBar';


function Login() {
  const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  }
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  }

  const handleEmailSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    const requestBody = { email: email, password: password };

    const method = 'POST';
    const url = `${BASE_URL}/admin/login`;

    const wrongResponse = () => {
      setAlertMessage({ success: '', error: 'Wrong Credentials' });
    };

    const rightResponse = (data) => {
      if (data) {
        if (data.data.roleId !== null && data.data.roleId !== undefined) {
          const sessionToken = data.data.token;
          const sessionExpiration = new Date().getTime() + 5000 * 60 * 5000;
          sessionStorage.setItem('sessionToken', sessionToken);
          sessionStorage.setItem('sessionExpiration', sessionExpiration);
        }
        dispatch(setUserId(data.data.admin.id));
        dispatch(setUserRole(data.data.admin.role_id));
        dispatch(setUserName(data.data.admin.name))
        dispatch(setPermissions(data.data.admin.permissions));
        dispatch(setIsSuperAdmin(data.data.admin.superAdmin));

      }
    };

    const authenticated = await handleDynamicRequest(method, url, requestBody, wrongResponse, rightResponse);


    console.log('authenticated user data', authenticated);
    if (authenticated) {
      console.log('User is authenticated');
      window.location = '/';
    }
  };

  return (
    <section>
      <NavBar login={{ status: true }} />
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-12">
            <div className="login-card">
              <form className="theme-form login-form" onSubmit={handleEmailSubmit}>
                <h4>Login</h4>
                <h6>Welcome back! Log in to your account.</h6>
                <div className="form-group">
                  <label>Email Address</label>
                  <div className="input-group">
                    <span className="input-group-text"><i className="icon-email"></i></span>
                    <input
                      className="form-control"
                      type="email"
                      name="email"
                      placeholder="Test@gmail.com"
                      value={email}
                      onChange={handleEmailChange}
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <div className="input-group">
                    <span className="input-group-text"><i className="icon-lock"></i></span>
                    <input
                      className="form-control"
                      type="password"
                      name="password"
                      placeholder="*********"
                      value={password}
                      onChange={handlePasswordChange}
                      required
                    />
                    <div className="show-hide"><span className="show"></span></div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="checkbox">
                    <input id="checkbox1" type="checkbox" />
                    <label className="text-muted" htmlFor="checkbox1">Remember password</label>
                  </div>
                </div>
                <div className="form-group">
                  <button className="btn btn-primary btn-block" type="submit">Sign in</button>
                </div>
              </form>
              {alertMessage.error && <div className="alert alert-danger">{alertMessage.error}</div>}
              {alertMessage.success && <div className="alert alert-success">{alertMessage.success}</div>}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;