import React, { useState, useEffect, useRef } from 'react';
import Component from '../../components/Component'
import { BASE_URL, handleDynamicRequest, SetAlert } from '../../components/CommonExport';

import { TextField, Grid, Autocomplete, Button, TextareaAutosize, IconButton } from '@mui/material';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { DataGrid } from '@mui/x-data-grid';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EditIcon from '@mui/icons-material/Edit'; // Import EditIcon
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import AwsConfig from '../../components/AwsConfig';
import AWS from 'aws-sdk';



const S3_BUCKET = process.env.REACT_APP_S3_BUCKET
const REGION = process.env.REACT_APP_REGION
const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY
const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY



function PlayerPhoto() {
    const [playerData, setPlayerData] = useState([]);
    const [selectedUser, setSelectedUser] = useState([]);
    const [competitionData, setCompetitionData] = useState([]);
    const [matchData, setMatchData] = useState([]);
    const [teamDataDropdown, setTeamDataDropdown] = useState([]);
    const [teamFilterDropdown, setTeamFilterDropdown] = useState('');
    const [teamFilterAdd, setTeamFilterAdd] = useState('');
    const [teamData, setTeamData] = useState([]);
    const [teamFilter, setTeamFilter] = useState([]);
    const [matchId, setMatchId] = useState();
    const [competitionFilterData, setCompetitionFilterData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [filteredData, setFilteredData] = useState(playerData);
    const [modal, setModal] = useState(false);
    const [alertMessage, setAlertMessage] = useState({ success: '', error: '' });
    const playerNames = playerData?.map(player => player.title);
    const [playerNameFilter, setPlayerNameFilter] = useState('');
    const [dateFromFilter, setDateFromFilter] = useState('');
    const [dateToFilter, setDateToFilter] = useState('');
    const [matchFilter, setMatchFilter] = useState(null);
    const [formData, setFormData] = useState({
        team_id: null,
        player_id: '',
        image_url: null,
        format: '',
        cid: '',
        team_name: ''
    });


    const [codeFormat, setCodeFormat] = useState([
        { code: 1, description: 'ODI(One Day International)' },
        { code: 2, description: 'TEST' },
        { code: 3, description: 'T20I(Twenty 20 International)' },
        { code: 4, description: 'List A(Limited Over Domestic Match)' },
        { code: 5, description: 'First Class' },
        { code: 6, description: 'T20(Domestic)' },
        { code: 7, description: 'Women ODI' },
        { code: 8, description: 'Women T20' },
        { code: 9, description: 'Youth ODI' },
        { code: 10, description: 'Youth T20' },
    ]);
    const [format, setFormat] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadedImageUrl, setUploadedImageUrl] = useState('');
    const [limit, setLimit] = useState(30)
    const [offset, setOffset] = useState(0)
    const [modalEdit, setModalEdit] = useState(false);
    const toggleEdit = () => setModalEdit(!modalEdit);
    const toggle = () => setModal(!modal);

    useEffect(() => {
        fetchPlayerData();
        fetchCompetionData();
    }, [limit, offset]);

    const fetchPlayerData = async () => {
        let requestBody = {};
        setPlayerData([]);
        setFilteredData([]);
        if (teamFilterDropdown?.teamid) {
            requestBody.team_id = teamFilterDropdown.teamid;
        }
        if (format?.code) {
            requestBody.format = format.code;
        }
        requestBody.limit = limit
        requestBody.offset = offset
        const method = 'POST';
        const url = `${BASE_URL}/players/sorted/images`;
        const wrongResponse = () => {
            setAlertMessage({ success: '', error: 'Wrong Credentials' });
        };
        const rightResponse = (data) => {
            if (data) {

                let updatedFilteredData = data.data.players.map((player, index) => {
                    return {
                        ...player,
                        id: index + 1 // Increment index by 1 to start from 1
                    };
                });
                setFilteredData(updatedFilteredData);
                setPlayerData(updatedFilteredData);
            }
        };
        try {
            await handleDynamicRequest(method, url, requestBody, wrongResponse, rightResponse);
        } catch (error) {
            wrongResponse();
        }
    };
    const handleNext = () => {
        const newOffset = offset + 30;
        setOffset(newOffset);
    };

    const handlePrevious = () => {
        const newOffset = Math.max(0, offset - 30);
        setOffset(newOffset);
    };

    useEffect(() => {
        fetchPlayerData();
    }, [teamFilterDropdown, format]);

    const fetchCompetionData = async (e) => {
        const requestBody = {};
        const method = 'GET';
        const url = `${BASE_URL}/competitions`;
        const wrongResponse = () => {
            setAlertMessage({ success: '', error: 'Wrong Credentials' });
        };
        const rightResponse = (data) => {
            if (data) {
                setCompetitionData(data.data.competitions)
            }
        };
        handleDynamicRequest(method, url, requestBody, wrongResponse, rightResponse);
    };

    const fetchMatches = async (cid) => {
        if (cid !== null) {
            const requestBody = {};
            const method = 'GET';
            const url = `${BASE_URL}/matches`;
            const wrongResponse = () => {
                setAlertMessage({ success: '', error: 'Wrong Credentials' });
            };
            const rightResponse = (data) => {
                if (data && data.data && data.data.matches) {
                    const filteredMatches = data.data.matches.filter(match => match.competition && match.competition.cid === Number(cid));
                    setMatchData(filteredMatches);
                }
            };
            handleDynamicRequest(method, url, requestBody, wrongResponse, rightResponse);
        }
    };

    useEffect(() => {
        if (matchId) {
            const filteredMatches = matchData.filter(match => match.match_id && match.match_id === Number(matchId));
            if (filteredMatches.length > 0) {
                const { teama, teamb } = filteredMatches[0];
                setTeamData([{ id: teama.team_id, name: teama.name }, { id: teamb.team_id, name: teamb.name }]);
            }
        }
    }, [matchId]);

    useEffect(() => {
        fetch(`${BASE_URL}/tracking/match-teams`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMTJiNTBhNDgtNWU5ZS00MzNmLTgyNmMtMTJhNmQ3MmM5N2NkIiwiaWF0IjoxNzExNDU2OTEzLCJleHAiOjE3NDI1NjA5MTMsInBybSI6IjMwZDQyNzg2ZGMyNTU3MDBkZDkzNzViMWQ4ZDYwN2E2MzBhYzViMzJhNWU1MjUxODQ2ZDQyNzE1YjBlN2Y3YTllZjczZjAzZDk1MzI3OGQ5NTdjOTVmOGE2YTVkZjk1MTM3MDc4ZTY4ODNlNTU3MjUzODczZjViYjNjMDZiNzZmIn0.msUlenclhnnuqwdU9R5HL32rYnUVmdyITkPlzSWDcio'
            },
        })
            .then(response => response.json())
            .then(data => {
                setTeamDataDropdown(data.data.users);
            })
            .catch(error => console.error('Error fetching matches for dropdown:', error));
    }, []);

    const columns = [
        { field: 'title', headerName: 'Player Name', flex: 1 },
        { field: 'team_name', headerName: 'Team Name', flex: 1 },
        {
            field: 'format',
            headerName: 'Format',
            flex: 1,
            renderCell: (params) => {
                const formatDescription = codeFormat.find(item => item.code == params.row.format)?.description || 'Unknown Format';
                return <span>{formatDescription}</span>;
            }
        },

        {
            field: 'image_url',
            headerName: 'Photo',
            flex: 1,
            renderCell: (params) => (
                <a href={params.value} target="_blank" rel="noopener noreferrer">
                    <img src={params.value} alt={params.row.title} style={{ width: '70px', height: '70px' }} />
                </a>
            )
        },
        {
            field: 'cta',
            headerName: 'CTA',
            flex: 1,
            renderCell: (params) => (
                params.row.image_url ? (
                    <IconButton className='back-btn' style={{ background: '#ffffff' }} onClick={() => { toggleEdit(); setSelectedUser(params.row); }}>
                        <EditIcon style={{ cursor: 'pointer', color: '#ffffff' }} />
                    </IconButton>
                ) : (
                    <IconButton className='back-btn' style={{ background: '#ffffff' }} onClick={() => { toggleEdit(); setSelectedUser(params.row); }}>
                        <AddIcon style={{ cursor: 'pointer', color: '#ffffff' }} />
                    </IconButton>
                )
            )
        },
    ];

    const handleEdit = (row) => {

    };

    const handleAdd = (row) => {

    };

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchText(value);
        const filteredRows = playerData.filter((row) => {
            return Object.values(row).some((field) =>
                String(field).toLowerCase().includes(value)
            );
        });
        setFilteredData(filteredRows);
    };


    const handleSubmit = () => {
        const submissionData = {
            competition: competitionFilterData,
            match: matchFilter,
            team: teamFilter,
            player: playerNameFilter,
            image_url: formData.image_url,
        };

    };


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setIsUploading(true);

            const s3 = new AWS.S3({
                accessKeyId: ACCESS_KEY,
                secretAccessKey: SECRET_ACCESS_KEY,
                region: REGION,
            });

            const folderPath = `players/${teamFilterAdd.teamname}/`; // Specify the folder path
            const fileExtension = file.name.split('.').pop(); // Extract file extension

            // Replace spaces with underscores in the title
            const sanitizedTitle = selectedUser.title.replace(/\s+/g, '_');
            const format = selectedUser.format

            const params = {
                Bucket: S3_BUCKET,
                Key: `${folderPath}${sanitizedTitle}_${format}.${fileExtension}`,
                Body: file,
                // Remove ACL parameter
            };

            s3.upload(params, (err, data) => {
                if (err) {
                    console.error(err);
                    setIsUploading(false);
                    return;
                }

                setUploadedImageUrl(data.Location);
                setIsUploading(false);
            });
        }
    };

    const handleDeleteImage = () => {
        const s3 = new AWS.S3({
            accessKeyId: ACCESS_KEY,
            secretAccessKey: SECRET_ACCESS_KEY,
            region: REGION,
        });

        const folderPath = `players/${teamFilterAdd.teamname}/`; // Specify the folder path
        const sanitizedTitle = selectedUser.title.replace(/\s+/g, '_');

        const fileExtension = uploadedImageUrl.split('.').pop(); // Extract file extension
        const format = selectedUser.format;


        const params = {
            Bucket: S3_BUCKET,
            Key: `${folderPath}${sanitizedTitle}_${format}.${fileExtension}`,
        };

        s3.deleteObject(params, (err, data) => {
            if (err) {
                console.error(err);
                return;
            }
            // Update UI or state as needed
            setUploadedImageUrl('');
        });
    };

    const handleButtonClick = () => {
        document.getElementById('file-input').click();
    };



    const handleChangeEdit = (event, fieldName) => {
        const { value } = event.target;

        // Update selectedUser based on the field name
        setSelectedUser(prevUser => ({
            ...prevUser,
            [fieldName]: value
        }));
    };

    const handleEditSubmit = () => {
        console.log('selectedUser', selectedUser);
        const format = selectedUser.format || '';
        console.log('format', format);

        const requestBody = {
            team_id: teamFilterAdd.teamid,
            player_id: selectedUser.pid,
            image_url: uploadedImageUrl,
            format: format,
            cid: competitionFilterData.cid,
            team_name: teamFilterAdd.teamname
        };
        console.log(requestBody)

        toggleEdit();
        if (uploadedImageUrl) {
            const method = 'POST';
            const url = `${BASE_URL}/players/player/images`;
            const wrongResponse = () => {
                setAlertMessage({ success: '', error: 'Wrong Credentials' });
            };
            const rightResponse = (data) => {
                if (data) {
                    fetchPlayerData();
                    setTeamFilterAdd('');
                    setCompetitionFilterData('');
                    setUploadedImageUrl('');
                }
            };

            handleDynamicRequest(method, url, requestBody, wrongResponse, rightResponse);
        }
    }


    return (
        <Component>
            <div class="page-body">
                <div class="container-fluid">
                    <div class="page-header">
                        <div class="row">
                            <div className="col-sm-12">
                                <div className="d-flex align-items-center ">
                                    <IconButton style={{ background: '#FFFFFF' }} component={Link} to="/dashboards">
                                        <ArrowBackIcon />
                                    </IconButton>
                                    <h3 style={{ marginLeft: '20px' }}>Player Photo</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="card">
                                <div class="card-body">
                                    <Grid container spacing={2} sx={{ marginBottom: '20px' }}>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <Autocomplete
                                                id="match-filter"
                                                options={[{ id: 'all', name: 'All' }, ...teamDataDropdown]}
                                                getOptionLabel={(option) => option.teamname}
                                                value={teamFilterDropdown}
                                                onChange={(event, newValue) => {
                                                    setTeamFilterDropdown(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Select Team" variant="outlined" fullWidth />}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <Autocomplete
                                                id="format-filter"
                                                options={[{ id: 'all', name: 'All' }, ...codeFormat]}
                                                getOptionLabel={(option) => option.description}
                                                value={format?.description}
                                                onChange={(event, newValue) => {
                                                    setFormat(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} label="Select Format" variant="outlined" fullWidth />}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={10}>
                                            <TextField
                                                fullWidth
                                                label="Search"
                                                variant="outlined"
                                                value={searchText}
                                                onChange={handleSearch}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={2}>
                                            <Button style={{ width: '100%', height: '100%' }} variant="contained" color="primary" onClick={toggle}>
                                                Add Photo
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <DataGrid
                                        rows={filteredData}
                                        columns={columns}
                                        pageSize={25}
                                        rowsPerPageOptions={[25, 50, 100]}
                                        getRowId={(row) => row.id}
                                        rowHeight={100}
                                    />
                                    <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', margin: '20px 0' }}>
                                        <button onClick={handlePrevious} disabled={offset === 0}>
                                            Previous
                                        </button>
                                        <span>Page: {(offset / limit) + 1}</span>
                                        <button onClick={handleNext}>
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Add Player Image</ModalHeader>
                <ModalBody>
                    <form className="form theme-form">
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="match-filter"
                                    options={[{ id: 'all', title: 'All' }, ...competitionData]}
                                    getOptionLabel={(option) => option.title}
                                    value={competitionFilterData}
                                    onChange={(event, newValue) => {
                                        setCompetitionFilterData(newValue);
                                        fetchMatches(newValue.cid);
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Select Competition" variant="outlined" fullWidth />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="match-filter"
                                    options={[{ id: 'all', name: 'All' }, ...teamDataDropdown]}
                                    getOptionLabel={(option) => option.teamname}
                                    value={teamFilterAdd}
                                    onChange={(event, newValue) => {
                                        setTeamFilterAdd(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Select Team" variant="outlined" fullWidth />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="player-name-filter"
                                    options={playerNames}
                                    value={playerNameFilter}
                                    onChange={(event, newValue) => setPlayerNameFilter(newValue)}
                                    renderInput={(params) => <TextField {...params} label="Select Player" variant="outlined" />}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="Player Image"
                                    value={formData.image_name}
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <Button variant="contained" onClick={handleButtonClick}>
                                                Upload
                                            </Button>
                                        )
                                    }}
                                />
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                    id="file-input"
                                />
                                {formData.image_url && (
                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                        <img src={formData.image_url} alt="Player" style={{ width: '100px', height: '100px', marginRight: '10px' }} />
                                        <IconButton onClick={handleDeleteImage} color="secondary">
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                )}
                            </Grid>
                        </Grid>
                    </form>
                </ModalBody>
                <ModalFooter >
                    <Button color="primary" onClick={handleSubmit} >Submit</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={modalEdit} toggle={toggleEdit}>
                <ModalHeader toggle={toggleEdit}>Edit Player</ModalHeader>
                <ModalBody>
                    <form className="form theme-form">
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="match-filter"
                                    options={[{ id: 'all', title: 'All' }, ...competitionData]}
                                    getOptionLabel={(option) => option.title}
                                    value={competitionFilterData}
                                    onChange={(event, newValue) => {
                                        setCompetitionFilterData(newValue);
                                        fetchMatches(newValue.cid);
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Select Competition" variant="outlined" fullWidth />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="match-filter"
                                    options={[{ id: 'all', name: 'All' }, ...teamDataDropdown]}
                                    getOptionLabel={(option) => option.teamname}
                                    value={teamFilterAdd}
                                    onChange={(event, newValue) => {
                                        setTeamFilterAdd(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Select Team" variant="outlined" fullWidth />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    id="player-name-filter"
                                    options={playerNames}
                                    value={selectedUser?.title}
                                    onChange={(event, newValue) => setPlayerNameFilter(newValue)}
                                    renderInput={(params) => <TextField {...params} label="Select Player" variant="outlined" />}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="Player Image"
                                    value={formData.image_name}
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <Button variant="contained" onClick={handleButtonClick}>
                                                Upload
                                            </Button>
                                        ),
                                    }}
                                />
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                    id="file-input"
                                />
                                {uploadedImageUrl && (
                                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                        <img src={uploadedImageUrl} alt="Player" style={{ width: '100px', height: '100px', marginRight: '10px' }} />
                                        <IconButton onClick={handleDeleteImage} color="secondary">
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                )}
                            </Grid>
                        </Grid>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleEditSubmit}>Submit</Button>
                    <Button color="secondary" onClick={toggleEdit}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </Component>
    )
}

export default PlayerPhoto
