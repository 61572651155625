import { combineReducers } from 'redux';
import roleReducer from './Role/reducer';
import matchReducer from './Match/reducer';


const rootReducer = combineReducers({
    roleReducer, 
    matchReducer,
});

export default rootReducer;