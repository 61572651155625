import React from 'react'

function Footer() {
    const currentDate = new Date();
  
	const currentYear = new Date().getFullYear();
  return (
    <div>
         <footer class="footer">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12 footer-copyright text-center">
                <p class="mb-0">&copy;&nbsp;{currentYear}&nbsp;&nbsp; Dugout All rights reserved.</p>
              </div>
            </div>
          </div>
        </footer>
    </div>
  )
}

export default Footer
