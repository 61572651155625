import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../components/CommonExport.js';
import { DataGrid } from '@mui/x-data-grid';
import { TextField, Box, Typography, Paper, CircularProgress, Alert, IconButton, Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import Component from '../../components/Component.js';

const NewInstalls = () => {
    const [newInstallsData, setNewInstallsData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [month, setMonth] = useState(() => {
        const now = new Date();
        return `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}`;
    });

    useEffect(() => {
        if (!month) return; // Avoid fetching data before month is selected

        const fetchUsers = async () => {
            setLoading(true);
            setError(null);

            try {
                const response = await axios.get(`${BASE_URL}/admin/new-installs?month=${month}`);
                const newInstalls = response.data.data.map((item) => ({
                    ...item,
                    entry_date: new Date(item.entry_date).toLocaleDateString('en-GB'), // Format date to DD/MM/YYYY
                    total_installs: Number(item.newinstalls_count) + Number(item.unreg_users_count) + Number(item.reg_users_count),
                }));
                setNewInstallsData(newInstalls);
            } catch (err) {
                setError(err.response?.data?.message || 'Failed to fetch data');
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, [month]);

    const handleMonthChange = (event) => {
        console.log(event.target.value)
        setMonth(event.target.value);
    };

    const columns = [
        { field: 'entry_date', headerName: 'Date', width: 200, },
        { field: 'newinstalls_count', headerName: 'Installs', width: 150, align: 'center', headerAlign: 'center' },
        { field: 'unreg_users_count', headerName: 'Unregistered Users', width: 150, align: 'center', headerAlign: 'center' },
        { field: 'reg_users_count', headerName: 'Registered Users', width: 150, align: 'center', headerAlign: 'center' },
        { field: 'total_installs', headerName: 'Total Installs', width: 150, align: 'center', headerAlign: 'center' },
    ];
    const calculateTotals = (data) => {
        return {
          totalNewInstalls: data.reduce((acc, row) => acc + Number(row.newinstalls_count || 0), 0),
          totalUnregUsers: data.reduce((acc, row) => acc + Number(row.unreg_users_count || 0), 0),
          totalRegUsers: data.reduce((acc, row) => acc + Number(row.reg_users_count || 0), 0),
          totalInstalls: data.reduce((acc, row) => acc + Number(row.total_installs || 0), 0),

        };
      };
    const totals = calculateTotals(newInstallsData);
    

    return (
        <Component>
        <div className="page-body">
          <div className="container-fluid">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <div className="d-flex align-items-center">
                    <IconButton style={{ background: '#FFFFFF' }} component={Link} to="/">
                      <ArrowBackIcon />
                    </IconButton>
                    <h3 style={{ marginLeft: '20px' }}>New User Installs</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
        <Paper elevation={3} style={{ padding: '20px' }}>

            <Box mb={3} style={{ maxWidth: '300px' }}>
                <TextField
                    label="Select Month"
                    type="month"
                    variant="outlined"
                    fullWidth
                    value={month}
                    onChange={handleMonthChange}
                    helperText="Choose a year and month in 'yyyy-mm' format"
                />
            </Box>
            <Box
                    sx={{
                      backgroundColor: '#f9f9f9',
                      border: '1px solid #ddd',
                      padding: '16px',
                      borderRadius: '8px',
                      marginBottom: '20px'
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={4}><Typography>Total New Installs: {totals.totalNewInstalls}</Typography></Grid>
                      <Grid item xs={6} sm={4}><Typography>Total Unreg Users: {totals.totalUnregUsers}</Typography></Grid>
                      <Grid item xs={6} sm={4}><Typography>Total Reg users: {totals.totalRegUsers}</Typography></Grid>
                      <Grid item xs={6} sm={4}><Typography>Total Installs: {totals.totalInstalls}</Typography></Grid>
                    </Grid>
                  </Box>


            {loading ? (
                <Box display="flex" justifyContent="center" mt={3}>
                    <CircularProgress />
                </Box>
            ) : error ? (
                <Alert severity="error" mt={3}>
                    {error}
                </Alert>
            ) : (
                <Box mt={3} style={{ height: 400 }}>
                    <DataGrid
                        rows={newInstallsData}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        disableSelectionOnClick
                        getRowId={(row) => row.entry_date} // Use a unique field as the row ID
                    />
                </Box>
            )}
        </Paper>
        </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Component>
    );
};

export default NewInstalls;
