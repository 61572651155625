import { SET_USER_ROLE, SET_USER_ID, SET_USER_NAME, SET_JWTTOKEN, SET_U_PHOTO, LOGOUT, IS_SUPER_ADMIN , SET_PERMISSIONS} from "./actionTypes";

const initialState = {
  userRole: null,
  userId: null,
  userName: null,
  jwtToken: null,
  u_photo: null,
  logout: null,
  isSuperAdmin: null,
  permissions: null,
};

const roleReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_ROLE:
      return {
        ...state,
        userRole: action.payload,
      };

    case SET_USER_ID:
      return {
        ...state,
        userId: action.payload,
      };

    case SET_USER_NAME:
      return {
        ...state,
        userName: action.payload,
      };

    case SET_JWTTOKEN:
      return {
        ...state,
        jwtToken: action.payload,
      };

    case SET_U_PHOTO:
      return {
        ...state,
        u_photo: action.payload,
      };
    
    case IS_SUPER_ADMIN:
      return {
        ...state,
        isSuperAdmin: action.payload,
      };
    
    case SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      };

    case LOGOUT:
      return initialState;

    

    default:
      return state;
  }
};

export default roleReducer;