import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../../../components/CommonExport.js';
import './editCompetitions.css';
import axios from 'axios';
import Component from '../../../components/Component.js';
import { IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';

const CompetitionsList = () => {
  const [competitions, setCompetitions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [newPriority, setNewPriority] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState(null);
  const [dialogCompetitionId, setDialogCompetitionId] = useState(null);

  useEffect(() => {
    fetch(`${BASE_URL}/admin/competitions/get_competitions`)
      .then(response => response.json())
      .then(data => {
        console.log(data)
        setCompetitions(data.data.competitions);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [editingId, dialogCompetitionId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleEditClick = (id, currentPriority) => {
    setEditingId(id);
    setNewPriority(currentPriority);
  };

  const handleDeleteClick = (id, del) => {
    setDialogCompetitionId(id);
    setDialogType(del ? 'restore' : 'delete');
    setDialogOpen(true);
  };

  const handleSavePriority = async (id, priority) => {
    await axios.put(`${BASE_URL}/admin/competitions/update_competition/${id}`, {
      priority_order: priority
    });
    setEditingId(null);
    setNewPriority('');
  };

  const handleConfirmAction = async () => {
    if (dialogType === 'delete') {
      await axios.put(`${BASE_URL}/admin/competitions/update_competition/${dialogCompetitionId}`, { del: true });
    } else if (dialogType === 'restore') {
      await axios.put(`${BASE_URL}/admin/competitions/update_competition/${dialogCompetitionId}`, { restore: true });
    }
    setDialogOpen(false);
    setDialogCompetitionId(null);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogCompetitionId(null);
  };

  return (
    <Component>
      <div className="page-body">
        <div className="container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <div className="d-flex align-items-center">
                  <IconButton style={{ background: '#FFFFFF' }} component={Link} to="/competitions">
                    <ArrowBackIcon />
                  </IconButton>
                  <h3 style={{ marginLeft: '20px' }}>Edit Competitions</h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <table>
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Category</th>
                        <th>Created at</th>
                        <th>Updated at</th>
                        <th>Deleted at</th>
                        <th>Game format</th>
                        <th>Season</th>
                        <th>Priority</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {competitions.map((competition) => (
                        <tr
                          key={competition.id}
                          className={competition.deleted_at ? 'deleted' : 'not_deleted'}
                        >
                          <td>{competition.title}</td>
                          <td>{competition.category}</td>
                          <td>{competition.created_at}</td>
                          <td>{competition.updated_at}</td>
                          <td>{competition.deleted_at}</td>
                          <td>{competition.game_format}</td>
                          <td>{competition.season}</td>
                          <td>
                            {editingId === competition.id ? (
                              <div>
                                <input
                                  type="text"
                                  value={newPriority}
                                  onChange={(e) => setNewPriority(e.target.value)}
                                />
                                <button onClick={() => setEditingId(null)}>Close</button>
                              </div>
                            ) : (
                              competition.priority_order
                            )}
                            {editingId === competition.id && (
                              <button onClick={() => handleSavePriority(competition.id, newPriority)}>
                                Save
                              </button>
                            )}
                          </td>
                          <td>
                            <button
                              className="edit"
                              onClick={() => handleEditClick(competition.id, competition.priority_order)}
                            >
                              Edit
                            </button>
                            <button
                              className="delete"
                              onClick={() => handleDeleteClick(competition.id, competition.deleted_at)}
                            >
                              {competition.deleted_at ? 'Restore' : 'Delete'}
                            </button>
                            {/* New button to navigate to competition details */}
                            <Link to={`./${competition.cid}`}>
                              <button className="view">View</button>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {dialogType === 'delete' ? 'Confirm Delete' : 'Confirm Restore'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogType === 'delete'
              ? 'Are you sure you want to delete this competition?'
              : 'Are you sure you want to restore this competition?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            No
          </Button>
          <Button onClick={handleConfirmAction} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Component>
  );
};

export default CompetitionsList;
