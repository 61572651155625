
export const SET_USER_ROLE = 'SET_USER_ROLE';

export const SET_USER_ID='SET_USER_ID';

export const SET_USER_NAME='SET_USER_NAME';

export const SET_JWTTOKEN='SET_JWTTOKEN';

export const SET_U_PHOTO='SET_U_PHOTO';

export const LOGOUT='LOGOUT';

export const SET_ACCESS = 'SET_ACCESS'; 

export const IS_SUPER_ADMIN = 'IS_SUPER_ADMIN';

export const SET_PERMISSIONS = 'SET_PERMISSIONS';