import { SET_USER_ROLE, SET_USER_ID,  SET_USER_NAME, SET_JWTTOKEN,SET_U_PHOTO, LOGOUT, SET_ACCESS, IS_SUPER_ADMIN, SET_PERMISSIONS} from "./actionTypes";


export const setUserRole = (role) => ({
    type: SET_USER_ROLE,
    payload: role,
  });

  
export const setUserId = (_id) => ({
    type: SET_USER_ID,
    payload: _id,
  });

  
export const setUserName = (fullName) => ({
  type: SET_USER_NAME,
  payload: fullName,
});

export const setJwtToken = (jwtToken) => ({
  type: SET_JWTTOKEN,
  payload: jwtToken,
});

export const setU_photo = (u_photo) => ({
  type: SET_U_PHOTO,
  payload: u_photo,
});

export const setLogout = (logout) => ({
  type: LOGOUT,
  payload: logout,
});

export const setAccess = (access) => ({
  type: SET_ACCESS,
  payload: access, 
});

export const setIsSuperAdmin = (isSuperAdmin) => ({
  type: IS_SUPER_ADMIN,
  payload: isSuperAdmin,
});

export const setPermissions = (permissions) => ({
  type: SET_PERMISSIONS,
  payload: permissions,
});

