import { findAllByDisplayValue } from "@testing-library/react";

export const handleDynamicRequest = async (method, url, requestBody, wrongResponse, rightResponse) => {
    try {
        const options = {
            method: method,
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMTJiNTBhNDgtNWU5ZS00MzNmLTgyNmMtMTJhNmQ3MmM5N2NkIiwiaWF0IjoxNzExNDU2OTEzLCJleHAiOjE3NDI1NjA5MTMsInBybSI6IjMwZDQyNzg2ZGMyNTU3MDBkZDkzNzViMWQ4ZDYwN2E2MzBhYzViMzJhNWU1MjUxODQ2ZDQyNzE1YjBlN2Y3YTllZjczZjAzZDk1MzI3OGQ5NTdjOTVmOGE2YTVkZjk1MTM3MDc4ZTY4ODNlNTU3MjUzODczZjViYjNjMDZiNzZmIn0.msUlenclhnnuqwdU9R5HL32rYnUVmdyITkPlzSWDcio'
            },
            body: method !== 'GET' && method !== 'HEAD' ? JSON.stringify(requestBody) : undefined,
        };

        const response = await fetch(url, options);
        const data = await response.json();
        if (!response.ok) {
            wrongResponse();
            return false
        }
        else if (response.ok) {
            rightResponse(data);
            console.log("this is right response", data)
            return data
            
        }
    } catch (error) {
        console.error('API Error:', error);
        return false
    }
};



export const handleDynamicRequestHeader = async (method, url, requestBody,token, wrongResponse, rightResponse) => {
    try {
         const response = await fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
               'Authorization':token
            },
            body: method !== 'GET' && method !== 'HEAD' ? JSON.stringify(requestBody) : undefined,
        });
       const data = await response.json();
        setTimeout(()=>{
            if (!response.ok) {
                wrongResponse();
            }
           else if (response.ok) {
            if(data){
                rightResponse(data);
            }
            }
        });
        
    } catch (error) {
        console.error('API Error:', error);
    }
};

export const handleDynamicRequestHeaderMulter = async (method, url, formData,token, wrongResponse, rightResponse) => {
    try {
        const response = await fetch(url, {
            method: method,
            headers: {

                'Authorization': token
            },
            body: formData
        });
        

        const data = await response.json();

        if (!response.ok) {
            wrongResponse();
        } else {
            rightResponse(data);
        }
    } catch (error) {
        console.error('API Error:', error);
    }
};
