import AWS from 'aws-sdk';
const S3_URL = "https://qa-dugout.s3.ap-south-1.amazonaws.com/";
// Set up AWS credentials (replace with your actual credentials)
const credentials = {
    accessKeyId: 'AKIA5ASO4JECYTSYSU6R',
    secretAccessKey: 'vtd2WKbvR+5EoqmNVMdrmKDEjI1OQUZysl2HkgPN'
};

// Update the AWS configuration with credentials
AWS.config.update(credentials);

// Export the AWS object
export default {AWS, S3_URL};

