import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Grid, TextField, InputLabel, Select, MenuItem, Autocomplete, Radio, RadioGroup, FormControlLabel, FormControl, Checkbox } from '@mui/material';
import { useSelector } from 'react-redux';
import { BASE_URL } from '../../components/AppConfig.js';
import axios from 'axios';

const SendNotification = () => {
    const [modal, setModal] = useState(false); // Modal state
    const [formData, setFormData] = useState({
        title: '',
        comment: '',
        message: '',
        deepLink: '',
    });
    const [disabled, setDisabled] = useState(false)
    const [deepLinkType, setDeepLinkType] = useState('');
    const [playerId, setPlayerId] = useState(0);
    const [playersData, setPlayersData] = useState([]);
    const [userReg, setUserReg] = useState(true);
    const [schedule, setSchedule] = useState(false);
    const [scheduleDateTime, setScheduleDateTime] = useState(null);

    const matches = useSelector((state) => state.matchReducer.matches);
    const matchId = matches?.match_id;

    useEffect(() => {
    }, [matchId]);

    const submitNotification = async () => {
        try {
            const schedule_date = new Date(scheduleDateTime).toISOString();
            console.log(`${BASE_URL}/notification${userReg ? '' : '?unreg=true'}${schedule ? `?schedule_at=${schedule_date}` : ''}`)
            const result = await axios.post(`${BASE_URL}/notification${userReg ? '' : '?unreg=true'}${schedule ? `?schedule_at=${schedule_date}` : ''}`,
                formData
            )
            return result.data.data
        } catch (err) {
            console.log(err)
            window.alert('Error sending notification');
        }
    }
    const handleSubmit = async () => {
        setDisabled(true)
        const response = await submitNotification()
        setDisabled(false)
        if(schedule){
            window.alert('Notification Scheduled Successfully');
        }
        else{
            console.log(response.result)
            const noOfUsers = response.result
            window.alert(`Notifications sent to ${noOfUsers} users `);
        }
        setFormData({
            title: '',
            comment: '',
            message: '',
            deepLink: '',
        });
        setDeepLinkType('');
        setModal(false);
    };

    async function getPlayersBymatchId() {
        try {
            const response = await axios.get(`${BASE_URL}/players/playing-eleven/${matchId}`);
            setPlayersData(response.data.data.playingEleven || []);
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            {/* Button to open modal */}
            <Button color="primary" onClick={() => setModal(true)}>
                Send Notification
            </Button>

            {/* Modal */}
            <Modal
                isOpen={modal}
                toggle={() => setModal(false)}
            >
                <ModalHeader toggle={() => setModal(false)}>Send Notification</ModalHeader>
                <ModalBody>
                    <form className="form theme-form">
                        <Grid container spacing={4}>
                            {/* Title Field */}
                            <Grid item xs={12} container justifyContent="center" alignItems="center">
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        row
                                        aria-label="userType"
                                        name="userType"
                                        value={userReg ? "registered" : "non-registered"}
                                        onChange={(event) => {
                                            const isRegistered = event.target.value === "registered";
                                            setUserReg(isRegistered);
                                            console.log(userReg)
                                        }}
                                    >
                                        <FormControlLabel
                                            value="registered"
                                            control={<Radio />}
                                            label="Registered Users"
                                        />
                                        <FormControlLabel
                                            value="non-registered"
                                            control={<Radio />}
                                            label="Non-Registered Users"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={schedule}
                                            onChange={(e) => setSchedule(e.target.checked)}
                                            name="schedule"
                                        />
                                    }
                                    label="Schedule Notification"
                                />
                            </Grid>

                            {/* Date-Time Picker (Visible if Schedule is Enabled) */}
                            {schedule && (
                                <Grid item xs={12}>
                                    <TextField
                                        label="Select Date and Time"
                                        type="datetime-local"
                                        variant="outlined"
                                        fullWidth
                                        value={scheduleDateTime}
                                        onChange={(e) => {
                                            const selectedDate = new Date(e.target.value).toISOString();
                                            const currentDate = new Date().toISOString();

                                            if (selectedDate < currentDate) {
                                                window.alert("Please select a future date and time");
                                                return;
                                            }
                                            setScheduleDateTime(e.target.value);
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{
                                            backgroundColor: 'white',
                                            borderRadius: '8px',
                                            marginBottom: 2, // Add margin to the bottom to ensure spacing between elements
                                        }}
                                    />
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Title"
                                    variant="outlined"
                                    placeholder="Enter Title"
                                    value={formData.title}
                                    onChange={(e) =>
                                        setFormData({ ...formData, title: e.target.value })
                                    }
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Message"
                                    variant="outlined"
                                    placeholder="Enter Message"
                                    value={formData.message}
                                    onChange={(e) =>
                                        setFormData({ ...formData, message: e.target.value })
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Comment"
                                    variant="outlined"
                                    placeholder="Enter Comment"
                                    value={formData.comment}
                                    onChange={(e) =>
                                        setFormData({ ...formData, comment: e.target.value })
                                    }
                                />
                            </Grid>

                            {/* DeepLink Field */}
                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel>DeepLink</InputLabel>
                                    <Select
                                        label="DeepLink"
                                        value={deepLinkType || ''}
                                        onChange={(e) => {
                                            const deepLinkType = e.target.value;
                                            let deepLink = '';

                                            if (deepLinkType === 'home') {
                                                deepLink = 'dugout://';
                                            } else if (deepLinkType === 'matchwall') {
                                                deepLink = `dugout://(root)/match-detail/${matchId}`;
                                            } else if (deepLinkType === 'playerwall') {
                                                getPlayersBymatchId();
                                                deepLink = '';
                                            }

                                            setFormData({
                                                ...formData,
                                                deepLink,
                                            });
                                            setDeepLinkType(deepLinkType);
                                        }}
                                    >
                                        <MenuItem value="home">Home</MenuItem>
                                        <MenuItem value="matchwall">Match Wall</MenuItem>
                                        <MenuItem value="playerwall">Player Wall</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            {/* Player Selection for PlayerWall */}
                            {deepLinkType === 'playerwall' && (
                                <Grid item xs={12}>
                                    <Autocomplete
                                        options={playersData}
                                        getOptionLabel={(option) => option.name || ''}
                                        value={
                                            playersData.find(
                                                (player) => player.player_id === playerId
                                            ) || null
                                        }
                                        onChange={(event, newValue) => {
                                            const selectedPlayerId = newValue
                                                ? newValue.player_id
                                                : 0;
                                            setPlayerId(selectedPlayerId);
                                            setFormData({
                                                ...formData,
                                                deepLink: selectedPlayerId
                                                    ? `dugout://(tabs)/player-zone/${selectedPlayerId}?mid=${matchId}`
                                                    : '',
                                            });
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Player"
                                                placeholder="Search Player"
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                </Grid>

                            )}
                        </Grid>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSubmit} disabled={disabled}>
                        Submit
                    </Button>
                    <Button color="secondary" onClick={() => setModal(false)} disabled={disabled}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default SendNotification;
