import { SET_MATCHES, MATCH_LOGOUT} from "./actionTypes";


export const setMatches = (matches) => ({
    type: SET_MATCHES,
    payload: matches,
  });

  
export const setMatchLogout = (logout) => ({
  type: MATCH_LOGOUT,
  payload: logout,
});