import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem, Autocomplete } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import './playerHighlights.css';
import Menu from '@mui/material/Menu';
import axios from 'axios';
import { BASE_URL, handleDynamicRequest } from '../../components/CommonExport';

const ActionMenu = ({ params, setEditFormData, setEditingId, setModalEdit, setDeleteModal, getPlayersBymatchId, getIcons }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} color="inherit">
                <MoreVertIcon />
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => {
                    getPlayersBymatchId()
                    getIcons()
                    setEditFormData({
                        player_id: params.row.player_id,
                        title: params.row.title,
                        description: params.row.description,
                        icon_url: params.row.icon_url,
                        type: params.row.type
                    });
                    setEditingId(Number(params.row.id));
                    setModalEdit(true);
                    handleClose();
                }}>Edit</MenuItem>
                <MenuItem onClick={() => {
                    setEditingId(Number(params.row.id));
                    setDeleteModal(true);
                    handleClose();
                }}>Delete</MenuItem>
            </Menu>
        </div>
    );
};

const PlayerHighlights = () => {
    const [modal, setModal] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [editingId, setEditingId] = useState(null);
    const [icons, setIcons] = useState([])
    const [formData, setFormData] = useState({
        player_id: 0,
        title: '',
        match_id: 0,
        description: '',
        icon_url: '',
        type: 0
    });
    const [editFormData, setEditFormData] = useState({});
    const [highlightsData, setHighlightsData] = useState([]);
    const [playersData, setPlayersData] = useState([]);
    const matches = useSelector((state) => state.matchReducer.matches);

    const toggle = () => {
        setModal(!modal);
        setFormData({
            player_id: 0,
            title: '',
            match_id: 0,
            description: '',
            icon_url: '',
            type: 0
        });
        getPlayersBymatchId();
        getIcons();
    };

    const handleChange = (event, field) => {
        const value = event.target.value;
        if (modalEdit) {
            setEditFormData({ ...editFormData, [field]: value });
        } else {
            setFormData({ ...formData, [field]: value });
        }
    };

    async function getPlayerInsights() {
        try {
            const response = await axios.get(`${BASE_URL}/admin/player-insights?match_id=${matches.match_id}`);
            setHighlightsData(response.data.data.insights || []);
        } catch (err) {
            console.log(err);
        }
    }

    async function getIcons() {
        try {
            const response = await axios.get(`${BASE_URL}/icons`)
            setIcons(response.data.data.icons || [])
        } catch (err) {
            console.log(err)
            return err
        }
    }

    async function getPlayersBymatchId() {
        try {
            const response = await axios.get(`${BASE_URL}/players/playing-eleven/${matches.match_id}`);
            setPlayersData(response.data.data.playingEleven || []);
        } catch (err) {
            console.log(err);
        }
    }

    async function deletePlayerHighlight() {
        try {
            const response = await axios.delete(`${BASE_URL}/admin/player-insights/${editingId}`);
            await getPlayerInsights();
            console.log(response);
        } catch (err) {
            console.log(err);
            return err;
        }
    }
    const addPlayerInsight = async () => {
        try {
            const response = await axios.post(`${BASE_URL}/admin/player-insights`, formData);
            await getPlayerInsights();
            return response.data.data.playerInsights;
        } catch (err) {
            console.log(err);
            return err;
        }
    }

    async function editPlayerHighlight() {
        try {
            const response = await axios.put(`${BASE_URL}/admin/player-insights/${editingId}`, editFormData);
            console.log(response);
            await getPlayerInsights();
            return response.data.data.playerInsights;
        } catch (err) {
            console.log(err);
            return err;
        }
    }


    useEffect(() => {
        getPlayerInsights();
    }, []);

    useEffect(() => {
        if (matches?.match_id) {
            setFormData(prevData => ({
                ...prevData,
                match_id: matches.match_id
            }));
            setEditFormData(prevData => ({
                ...prevData,
                match_id: matches.match_id
            }));
        }
    }, [matches.match_id, modal, modalEdit]);

    const handleSubmit = async () => {
        if (modalEdit) {
            await editPlayerHighlight();
            setEditFormData({
                ...editFormData,
                player_id: 0,
                title: '',
                description: '',
                icon_url: '',
                type: 0
            })
            setModalEdit(false);
        } else {
            await addPlayerInsight();
            console.log(formData)
            setFormData({
                ...formData,
                player_id: 0,
                title: '',
                description: '',
                icon_url: '',
                type: 0
            });
            setModal(false);
        }
    };
    const handleDeletePlayerHighlight = async () => {
        await deletePlayerHighlight();
        setDeleteModal(false);
    }

    const columns = [
        { field: 'playername', headerName: 'Player', width: 200 },
        { field: 'title', headerName: 'Title', width: 200 },
        { field: 'description', headerName: 'Description', width: 300 },
        {
            field: 'deleted_at',
            headerName: 'Deleted',
            width: 100,
            renderCell: (params) => (
                params.value ? 'true' : 'false'
            ),
        },
        {
            field: 'icon_url', headerName: 'Icon', width: 100, renderCell: (params) => (
                <img src={params.value} alt="icon" style={{ width: '24px', height: '24px' }} />
            )
        },
        {
            field: 'type', headerName: 'Type', width: 100, renderCell: (params) => (
                params.value === 1 ? 'Batsman' : params.value === 2 ? 'Bowler' : params.value === 3? 'Impression': 'Unknown'
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: (params) => (
                <ActionMenu
                    params={params}
                    setEditFormData={setEditFormData}
                    setEditingId={setEditingId}
                    setModalEdit={setModalEdit}
                    setDeleteModal={setDeleteModal}
                    getPlayersBymatchId={getPlayersBymatchId}
                    getIcons={getIcons}
                />
            )
        }
    ];

    return (
        <>
            <div className="card-body">
                <Button color="primary" onClick={toggle}>
                    Add Player Highlight
                </Button>

                {highlightsData.length > 0 && (
                    <DataGrid
                        rows={highlightsData}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10, 25, 50]}
                        getRowId={(row) => row.id}
                        style={{ height: '400px', marginTop: '20px' }}
                        getRowClassName={(params) =>
                            params.row.deleted_at ? 'row-deleted' : ''
                        }
                    />
                )}
            </div>

            <Modal isOpen={modal || modalEdit} toggle={() => {
                modal ? setModal(!modal) : setModalEdit(!modalEdit);
            }}>
                <ModalHeader toggle={() => modal ? setModal(!modal) : setModalEdit(!modalEdit)}>
                    {modalEdit ? 'Edit Player Highlight' : 'Add Player Highlights'}
                </ModalHeader>
                <ModalBody>
                    <form className="form theme-form">
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined">
                                    <Autocomplete
                                        options={playersData}
                                        getOptionLabel={(option) => option.name || ''}
                                        value={
                                            playersData.find(
                                                player => player.player_id === (modalEdit ? editFormData.player_id : formData.player_id)
                                            ) || null
                                        }
                                        onChange={(event, newValue) => {
                                            const selectedPlayerId = newValue ? newValue.player_id : '';
                                            if (modalEdit) {
                                                setEditFormData({ ...editFormData, player_id: selectedPlayerId });
                                            } else {
                                                setFormData({ ...formData, player_id: selectedPlayerId });
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Player Title"
                                                placeholder="Search Player"
                                                variant="outlined"
                                            />
                                        )}
                                    />

                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Title"
                                    variant="outlined"
                                    placeholder="Enter Title"
                                    value={modalEdit ? editFormData.title || '' : formData.title || ''}
                                    onChange={(event) => handleChange(event, 'title')}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Description"
                                    variant="outlined"
                                    placeholder="Enter Description"
                                    value={modalEdit ? editFormData.description || '' : formData.description || ''}
                                    onChange={(event) => handleChange(event, 'description')}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined">
                                    <Autocomplete
                                        options={icons}
                                        getOptionLabel={(option) => option.title || ''}
                                        value={icons.find(
                                            icon => icon.logo_url === (modalEdit ? editFormData.icon_url : formData.icon_url)) || null}
                                        onChange={(event, newValue) => {
                                            const selectedIconUrl = newValue ? newValue.logo_url : '';
                                            if (modalEdit) {
                                                setEditFormData({ ...editFormData, icon_url: selectedIconUrl });
                                            }
                                            else {
                                                setFormData({ ...formData, icon_url: selectedIconUrl });
                                            }
                                        }}
                                        renderOption={(props, option) => (
                                            <li {...props} style={{ display: 'flex', alignItems: 'center' }}>
                                                <img
                                                    src={option.logo_url}
                                                    alt="icon"
                                                    style={{ width: '24px', height: '24px', marginRight: '8px' }}
                                                />
                                                {option.title}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Icon"
                                                placeholder="Search Icon"
                                                variant="outlined"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    startAdornment: formData.icon_url || editFormData.icon_url && (
                                                        <img
                                                            src={formData.icon_url || editFormData.icon_url}
                                                            alt="icon"
                                                            style={{ width: '24px', height: '24px', marginRight: '8px' }}
                                                        />
                                                    ),
                                                }}
                                            />
                                        )}
                                    />

                                </FormControl>


                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel>Type</InputLabel>
                                    <Select
                                        label="Type"
                                        value={modalEdit ? editFormData.type || '' : formData.type || ''}
                                        onChange={(event) => handleChange(event, 'type')}
                                    >
                                        <MenuItem value={1}>Batsman</MenuItem>
                                        <MenuItem value={2}>Bowler</MenuItem>
                                        <MenuItem value={3}>Impression</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                        </Grid>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSubmit}>Submit</Button>
                    <Button color="secondary" onClick={() => {
                        if (modalEdit) {
                            setModalEdit(false)
                        }
                        else {
                            setModal(false)
                        }

                    }}>Cancel</Button>
                </ModalFooter>
            </Modal>

            {/* Delete Confirmation Modal */}
            <Modal isOpen={deleteModal} toggle={() => setDeleteModal(false)}>
                <ModalHeader toggle={() => setDeleteModal(false)}>Confirm Delete</ModalHeader>
                <ModalBody>Are you sure you want to delete this highlight?</ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={() => {
                        handleDeletePlayerHighlight();
                    }}>Delete</Button>
                    <Button color="secondary" onClick={() => setDeleteModal(false)}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </>
    );
};
export default PlayerHighlights;