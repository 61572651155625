import React from 'react'
import Component from '../../components/Component'
import { Container, Typography, Grid, Paper, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function ContentDashboard() {
    return (
        <div>
            <Component>
                <div class="page-body">
                    <div class="container-fluid">
                        <div class="page-header">
                            <div class="row">
                                <div className="col-sm-12">
                                    <div className="d-flex align-items-center ">
                                        <IconButton style={{ background: '#FFFFFF' }} component={Link} to="/dashboards">
                                            <ArrowBackIcon />
                                        </IconButton>
                                        <h3 style={{ marginLeft: '20px' }}>Content Dashboard</h3>
                                    </div>
                                </div>
                               
                            </div>

                        </div>
                    </div>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="card">
                                    <div class="card-body" style={{ height: '75vh' }}>
                                        <div class="row">
                                            <Container maxWidth="lg" sx={{ marginTop: '64px' }}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={4}>
                                                        <Link to="/user-dashboard" style={{ textDecoration: 'none' }}>
                                                            <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center', backgroundColor: '#2C3E50', color: '#FFFFFF' }}>
                                                                <Typography variant="h5" gutterBottom>User Dashboard</Typography>
                                                            </Paper>
                                                        </Link>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Link to="/player-dashboard" style={{ textDecoration: 'none' }}>
                                                            <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center', backgroundColor: '#2980B9', color: '#FFFFFF' }}>
                                                                <Typography variant="h5" gutterBottom>Player Dashboard</Typography>
                                                            </Paper>
                                                        </Link>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Link to="/match-dashboard" style={{ textDecoration: 'none' }}>
                                                            <Paper elevation={3} sx={{ padding: '20px', textAlign: 'center', backgroundColor: '#27AE60', color: '#FFFFFF' }}>
                                                                <Typography variant="h5" gutterBottom>Match Dashboard</Typography>
                                                            </Paper>
                                                        </Link>
                                                    </Grid>
                                                </Grid>
                                            </Container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </Component>
        </div>
    )
}

export default ContentDashboard
