import React from 'react'
import './navbar.css'
import ToggleBtn from './toggleBtn.js'
import axios from 'axios'
import { BASE_URL } from '../AppConfig.js' 
import {persistor} from '../../Store' 

function NavBar({login}) {
  const handleLogout = async() => {
    try{
      const response = await axios.put(`${BASE_URL}/admin/logout`,
        {}, 
        {
          withCredentials: true, 
          headers: {
            'Content-Type': 'application/json',
          }
        })
      persistor.purge()
      window.location = '/login';

    }catch(err){
      window.alert('Error logging out')
      console.log(err)
    }
   
  }
  return (
    <div>
         <div class="page-main-header">
        <div class="main-header-right row m-0">
          <div class="main-header-left">
            <div class="logo-wrapper"><a href="index.html"><img class="img-fluid" src="images/logo/logo.png" alt=""/>&nbsp;&nbsp;Dugout</a></div>
            {/* <div class="dark-logo-wrapper"><a href="index.html"><img class="img-fluid" src="images/logo/dark-logo.png" alt=""/></a></div> */}
            <ToggleBtn />
          </div>
          <div class="left-menu-header col">
            <ul>
              <li>
                {/* <form class="form-inline search-form">
                  <div class="search-bg"><i data-feather="search"></i>
                    <input class="form-control-plaintext" placeholder="Search here....."/>
                  </div>
                </form><span class="d-sm-none mobile-search search-bg"><i class="fa fa-search"></i></span> */}
              </li>
            </ul>
          </div>
          <div class="nav-right col pull-right right-menu p-0">
            <ul class="nav-menus">
            {!login?.status?
              <li class="onhover-dropdown p-0">
                <button class="btn btn-primary-light" onClick= {handleLogout} type="button"><i data-feather="log-out"></i>Log out</button>
              </li>
            :null}
              
            </ul>
          </div>
          <div class="d-lg-none mobile-toggle pull-right w-auto"><i data-feather="more-horizontal"></i></div>
        </div>
      </div>
    </div>
  )
}

export default NavBar
