import {Context} from '../context.js'
import {useContext} from 'react';
function GetUrl() {
    const env = localStorage.getItem('env') == 'true'?true:false || false;
    console.log(env)
    console.log('getting value from context', env)
        if(!env){
            return 'https://api.dugoutlive.com'
        }
        else{
            return 'https://prod-api.dugoutlive.com'
        }
}
export const BASE_URL= GetUrl();
