import React, { useState } from 'react';
import AddRoleForm from '../../components/addRole/addRole.js';

const AddRole = () => {
  const [roles, setRoles] = useState([]);

  const addRole = async (payload) => {
    try {
      const response = await fetch('http://your-api-url/roles', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      const result = await response.json();
      setRoles([...roles, result]);
    } catch (err) {
      console.error('Error adding role:', err);
    }
  };

  return (
    <div>
      <h1>Admin Roles</h1>
      <AddRoleForm onAddRole={addRole} />
      <ul>
        {roles.map((role, index) => (
          <li key={index}>{role.permission}</li>
        ))}
      </ul>
    </div>
  );
};

export default AddRole;