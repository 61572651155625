// src/components/AdminUsersTable.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './newUser.module.css';
import formatDate from '../../components/formatDate.js';
import Papa from 'papaparse';
import { BASE_URL } from '../../components/CommonExport.js';
import { DataGrid } from '@mui/x-data-grid';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import { TextField, Button, Box, Grid, Typography, Paper, IconButton } from '@mui/material';
import Component from '../../components/Component.js';

const UsersTable = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/admin/users/get_users`);
        const usersWithId = response.data.data.users.map((user, index) => ({
          ...user,
          id: index, // Ensure each user has a unique 'id' property
          index: index, // Add an extra field `index` to the user object
        }));
        setUsers(usersWithId);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const filterUsersByDate = (user) => {
    if (!startDate && !endDate) return true;

    const createdAt = new Date(user.created_at);
    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;

    if (start && end) {
      return createdAt >= start && createdAt <= end;
    } else if (start) {
      return createdAt >= start;
    } else if (end) {
      return createdAt <= end;
    }

    return true;
  };

  const filter = users.filter(filterUsersByDate);

  const filteredUsers = filter.map((user, index) => ({
    ...user,
    loginType: user.phone ? 'Phone' : user.email ? 'Email' : 'N/A',
    status: user.deleted_at ? 'Deleted' : 'Active',
  }));

  const exportToCSV = () => {
    const csvData = filteredUsers.map(user => ({
      Username: user.username,
      'Created At': formatDate(user.created_at),
      'Deleted At': user.deleted_at ? formatDate(user.deleted_at) : 'N/A',
      'Updated At': formatDate(user.updated_at),
      Email: user.email,
      'Device Platform': user.device_platform,
      'Device Status': user.device_status,
      'OsType': user.ostype,
      'Login Type': user.loginType,
      Status: user.status,
    }));

    const csv = Papa.unparse(csvData);

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'users.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const nonDeletedUsersCount = filteredUsers.filter(user => user.deleted_at === null).length;
  const usersWithUsername = filteredUsers.filter(user => user.deleted_at === null && user.username !== null).length;
  const usersWithNousername = filteredUsers.filter(user => user.deleted_at === null && user.username === null).length;
  const deletedUsersCount = filteredUsers.filter(user => user.deleted_at !== null).length;
  const androidUsersCount = filteredUsers.filter(user => user.ostype && user.ostype !== 'iOS').length;
  const iosUsersCount = filteredUsers.filter(user => user.ostype === 'iOS').length;
  const emailUsersCount = filteredUsers.filter(user => (user.email && !user.deleted_at)).length;
  const phoneUsersCount = filteredUsers.filter(user => (user.phone && !user.deleted_at)).length;

  const columns = [
    { field: 'username', headerName: 'Username', flex: 1 },
    { field: 'created_at', headerName: 'Created At', flex: 1, valueFormatter: ({ value }) => formatDate(value) },
    { field: 'deleted_at', headerName: 'Deleted At', flex: 1, valueFormatter: ({ value }) => value ? formatDate(value) : 'N/A' },
    { field: 'updated_at', headerName: 'Updated At', flex: 1, valueFormatter: ({ value }) => formatDate(value) },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'device_platform', headerName: 'Device Platform', flex: 1 },
    { field: 'status', headerName: 'User Status', flex: 1 },
    { field: 'ostype', headerName: 'OsType', flex: 1 },
    { field: 'loginType', headerName: 'Login Type', flex: 1 },
    { field: 'status', headerName: 'Status', flex: 1 }
  ];

  return (
    <Component>
      <div className="page-body">
        <div className="container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <div className="d-flex align-items-center">
                  <IconButton style={{ background: '#FFFFFF' }} component={Link} to="/">
                    <ArrowBackIcon />
                  </IconButton>
                  <h3 style={{ marginLeft: '20px' }}>New Users dashboard</h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <Box sx={{ width: '100%', p: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                      <Box sx={{ display: 'flex', gap: 2 }}>
                        <TextField
                          label="Start Date"
                          type="date"
                          value={startDate}
                          onChange={handleStartDateChange}
                          InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                          label="End Date"
                          type="date"
                          value={endDate}
                          onChange={handleEndDateChange}
                          InputLabelProps={{ shrink: true }}
                        />
                      </Box>
                      <Button variant="contained" color="primary" onClick={exportToCSV}>
                        Export to CSV
                      </Button>
                    </Box>

                    <Box sx={{ mb: 2 }}>
                      <Paper elevation={3} sx={{ p: 2 }}>
                        <Grid container spacing={2} className="stats-container">
                          <Box textAlign="center" m={2}>
                            {/* Active Users Heading */}
                            <Typography variant="h6" gutterBottom>
                              Active Users: {nonDeletedUsersCount}
                            </Typography>

                            {/* Subheadings in smaller text and different color */}
                            <Typography
                              variant="caption"
                              sx={{ color: 'green' }}
                              display="block"
                            >
                              Registered Users: {usersWithUsername}
                            </Typography>
                            <Typography
                              variant="caption"
                              sx={{ color: 'red' }}
                              display="block"
                            >
                              Unregistered Users: {usersWithNousername}
                            </Typography>

                          </Box>

                          <Grid item xs={3}>
                            <Typography variant="h6">Deleted Users: {deletedUsersCount}</Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="h6" >Android Users: {androidUsersCount}</Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="h6">iOS Users: {iosUsersCount}</Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="h6">Email Users: {emailUsersCount}</Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="h6">Phone Users: {phoneUsersCount}</Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Box>

                    <DataGrid
                      rows={filteredUsers}
                      columns={columns}
                      pageSize={10}
                      rowsPerPageOptions={[10, 25, 50]}
                      autoHeight
                      disableSelectionOnClick
                      getRowId={(row) => row.id}
                    />
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Component>




  );
};

export default UsersTable;

