import React, { useState, useEffect, useRef } from 'react';
import Component from '../../components/Component'
import { BASE_URL, handleDynamicRequest } from '../../components/CommonExport';

import {
    TextField, Grid, Autocomplete, Button, TextareaAutosize,
    Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Menu, MenuItem, IconButton
} from '@mui/material';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Switch } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';



function Polls() {
    const [playerData, setPlayerData] = useState([]);
    const [matchData, setMatchData] = useState([]);
    const [searchText, setSearchText] = React.useState('');
    const [filteredData, setFilteredData] = React.useState(playerData);
    const [modal, setModal] = useState(false);
    const [selectedWall, setSelectedWall] = useState('match');
    const [modalEdit, setModalEdit] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});
    const initialFormData = {
        question: "",
        options: [],
        isActive: "",
        location: "",
        startTime: "",
        endTime: "",
    };

    const [formData, setFormData] = useState(initialFormData);

    const matches = useSelector((state) => state.matchReducer.matches);
    const toggle = () => setModal(!modal);
    const toggleEdit = () => setModalEdit(!modalEdit);


    useEffect(() => {
        fetch(`${BASE_URL}/tracking/fetchTrackingPlayers`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMTJiNTBhNDgtNWU5ZS00MzNmLTgyNmMtMTJhNmQ3MmM5N2NkIiwiaWF0IjoxNzExNDU2OTEzLCJleHAiOjE3NDI1NjA5MTMsInBybSI6IjMwZDQyNzg2ZGMyNTU3MDBkZDkzNzViMWQ4ZDYwN2E2MzBhYzViMzJhNWU1MjUxODQ2ZDQyNzE1YjBlN2Y3YTllZjczZjAzZDk1MzI3OGQ5NTdjOTVmOGE2YTVkZjk1MTM3MDc4ZTY4ODNlNTU3MjUzODczZjViYjNjMDZiNzZmIn0.msUlenclhnnuqwdU9R5HL32rYnUVmdyITkPlzSWDcio'
            },
        })
            .then(response => response.json())
            .then(data => {
                setPlayerData(data.data.users);
            })
            .catch(error => console.error('Error fetching matches for dropdown:', error));
    }, []);

    useEffect(() => {
        fetchMatchData();
    }, []);


    const fetchMatchData = async () => {
        try {
            const response = await fetch(`${BASE_URL}/polls?mid=${matches.match_id}`, {
                method:'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMGEyMjc3MWQtMzZjZi00ZDM1LTgwYzktNGVmYjUzOWJjMzc0IiwiaWF0IjoxNzE4MzU4MDI3LCJleHAiOjE3NDk0NjIwMjcsInBybSI6IjY1ZDA4OTFjYjEzNmFkMmQ4NmVkZjE2ZjM4Yjg1ZjUzMmZjZjViY2VlODQ1Y2E2OGFkYTUyYjRhY2RiNGZhZDMwNGY1MTk4YzlhYjBhZDBiMWMxNDA1YzEyMjMxNWVkNzI0MzdmMjgwNTYzYjY1MTA0ODhmYWI1OGM0YTNjNmFiIn0.rCxUv0JA6OHfu3U8ZdD1rKP1ROEpZlaV9qmQXjI8aZc'
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch match data');
            }
            const data = await response.json();
            const matchesWithIST = data.data.polls.map(poll => ({
                ...poll,
                responseCount: poll.responses.length,
                starttime: adjustTimezone(poll.starttime),
                endtime: adjustTimezone(poll.endtime)
            }));
    
            setMatchData(matchesWithIST);
            setFilteredData(matchesWithIST);
        } catch (error) {
            console.error('Error fetching matches for dropdown:', error);
        }
    };
    
    // Function to adjust time zone from GMT to IST
    const adjustTimezone = (time) => {
        const date = new Date(time);
        date.setUTCHours(date.getUTCHours()); // Adding 5 hours to adjust for IST
        return date;
    };
    



    const playerNames = playerData?.map(player => player.title);
    const [playerNameFilter, setPlayerNameFilter] = useState('');
    const [dateFromFilter, setDateFromFilter] = useState('');
    const [dateToFilter, setDateToFilter] = useState('');
    const [matchFilter, setMatchFilter] = useState(null);

    const columns = [
        { field: 'question', headerName: 'Polls', flex: 1 },
        { field: 'responseCount', headerName: 'Responses', flex: 1 },
        { field: 'starttime', headerName: 'Start Time', flex: 1 },

        { field: 'endtime', headerName: 'End Time', flex: 1 },
        { field: 'location', headerName: 'Location', flex: 1 },
        {
            field: 'isactive',
            headerName: 'Status',
            flex: 1,
            renderCell: (params) => (
                <Switch
                    checked={params.value}
                    onChange={() => handleStatusToggle(params)}
                    color="primary"
                />
            ),
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            renderCell: (params) => <ActionMenu {...params} />
        },

    ];

    const handleStatusToggle =async (params) => {
        console.log('Toggled status for', params.id, 'to', !params.value, params.row);
        let status='';
        if(params.row.isactive===true){
            status=false;
        }else{
            status=true;
        }

        const requestBody = {
            id:params.row.id,
            match_id: params.row.match_id,
            question: params.row.question,
            options: params.row.options,
            isActive: status,
            location: params.row.location,
            startTime: params.row.starttime,
            endTime: params.row.endtime,
            player_id: params.row.player_id,
        };
        

        console.log()
          fetch(`${BASE_URL}/polls/${params.row.id}`, {
            method:'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMTJiNTBhNDgtNWU5ZS00MzNmLTgyNmMtMTJhNmQ3MmM5N2NkIiwiaWF0IjoxNzExNDU2OTEzLCJleHAiOjE3NDI1NjA5MTMsInBybSI6IjMwZDQyNzg2ZGMyNTU3MDBkZDkzNzViMWQ4ZDYwN2E2MzBhYzViMzJhNWU1MjUxODQ2ZDQyNzE1YjBlN2Y3YTllZjczZjAzZDk1MzI3OGQ5NTdjOTVmOGE2YTVkZjk1MTM3MDc4ZTY4ODNlNTU3MjUzODczZjViYjNjMDZiNzZmIn0.msUlenclhnnuqwdU9R5HL32rYnUVmdyITkPlzSWDcio'
            },
            body: JSON.stringify(requestBody)
        })
            .then(response => response.json())
            .then(data => {
             fetchMatchData();
            })
            .catch(error => console.error('Error fetching matches for dropdown:', error));
    };

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchText(value);
        const filteredRows = matchData.filter((row) => {
            return Object.values(row).some((field) =>
                String(field).toLowerCase().includes(value)
            );
        });
        setFilteredData(filteredRows);
    };

    const handleSubmit =async () => {
        const startDate = new Date(dateFromFilter);
        const endDate = new Date(dateToFilter);
    
        startDate.setUTCHours(startDate.getUTCHours()); 
        endDate.setUTCHours(endDate.getUTCHours());   
        const player =await playerData.find(player => player.title === formData.location);
    
        const requestBody = {
            match_id: matches.match_id,
            question: formData.question,
            options: formData.options,
            isActive: true,
            location: formData.location,
            startTime: startDate.toISOString(),
            endTime: endDate.toISOString(),
            player_id: player?.pid,
        };

        toggle();
      
       
    
        fetch(`${BASE_URL}/polls`, {
            method:'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMTJiNTBhNDgtNWU5ZS00MzNmLTgyNmMtMTJhNmQ3MmM5N2NkIiwiaWF0IjoxNzExNDU2OTEzLCJleHAiOjE3NDI1NjA5MTMsInBybSI6IjMwZDQyNzg2ZGMyNTU3MDBkZDkzNzViMWQ4ZDYwN2E2MzBhYzViMzJhNWU1MjUxODQ2ZDQyNzE1YjBlN2Y3YTllZjczZjAzZDk1MzI3OGQ5NTdjOTVmOGE2YTVkZjk1MTM3MDc4ZTY4ODNlNTU3MjUzODczZjViYjNjMDZiNzZmIn0.msUlenclhnnuqwdU9R5HL32rYnUVmdyITkPlzSWDcio'
            },
            body: JSON.stringify(requestBody)
        })
            .then(response => response.json())
            .then(data => {
                fetchMatchData();
                setFormData(initialFormData);
            })
            .catch(error => console.error('Error fetching matches for dropdown:', error));

    }

    const handleEdit=()=>{
        const startDate = new Date(dateFromFilter);
        const endDate = new Date(dateToFilter);
    
        startDate.setUTCHours(startDate.getUTCHours() ); 
        endDate.setUTCHours(endDate.getUTCHours());     
        // Construct the request body
        const player = playerData.find(player => player.title === selectedUser.location);
    
        const requestBody = {
            match_id: matches.match_id,
            question: selectedUser.question,
            options: selectedUser.options,
            isActive: selectedUser.isactive,
            location: selectedUser.location,
            startTime: startDate.toISOString(),
            endTime: endDate.toISOString(),
            player_id: player?.pid,
        };
        toggleEdit();
        fetch(`${BASE_URL}/polls/${selectedUser.id}`, {
            method:'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMTJiNTBhNDgtNWU5ZS00MzNmLTgyNmMtMTJhNmQ3MmM5N2NkIiwiaWF0IjoxNzExNDU2OTEzLCJleHAiOjE3NDI1NjA5MTMsInBybSI6IjMwZDQyNzg2ZGMyNTU3MDBkZDkzNzViMWQ4ZDYwN2E2MzBhYzViMzJhNWU1MjUxODQ2ZDQyNzE1YjBlN2Y3YTllZjczZjAzZDk1MzI3OGQ5NTdjOTVmOGE2YTVkZjk1MTM3MDc4ZTY4ODNlNTU3MjUzODczZjViYjNjMDZiNzZmIn0.msUlenclhnnuqwdU9R5HL32rYnUVmdyITkPlzSWDcio'
            },
            body: JSON.stringify(requestBody)
        })
            .then(response => response.json())
            .then(data => {
             fetchMatchData();
             setFormData(initialFormData);
            })
            .catch(error => console.error('Error fetching matches for dropdown:', error));
    }


    const handleChange = (event, field, index = null) => {
        if (field === "options" && index !== null) {
            const newOptions = [...formData.options];
            newOptions[index] = event.target.value;
            setFormData({ ...formData, options: newOptions });
        } else {
            setFormData({ ...formData, [field]: event.target.value });
        }
    };
    const handleChangeEdit = (event, field, index = null) => {
        if (field === "options" && index !== null) {
            const newOptions = [...selectedUser.options];
            newOptions[index] = event.target.value;
            setSelectedUser({ ...selectedUser, options: newOptions });
    
        } else {
            setSelectedUser({ ...selectedUser, [field]: event.target.value });
        }
    };

    useEffect(()=>{
        if(selectedUser.location==='Match Wall'){
            setSelectedWall('match'); 
          
        }else{
            setSelectedWall('player'); 
        }
        if (selectedUser) {
            const startDate = new Date(selectedUser.starttime);
            const endDate = new Date(selectedUser.endtime);
            const formattedStartDate = formatDate(startDate);
            const formattedEndDate = formatDate(endDate);
            setDateFromFilter(formattedStartDate);
            setDateToFilter(formattedEndDate);
        }
    
    },[selectedUser])

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = `${(date.getMonth() + 1)}`.padStart(2, '0');
        const day = `${date.getDate()}`.padStart(2, '0');
        const hours = `${date.getHours()}`.padStart(2, '0');
        const minutes = `${date.getMinutes()}`.padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };


    const addOption = () => {
        setFormData({ ...formData, options: [...formData.options, ""] });
        if(selectedUser &&  selectedUser.length>0){
            setSelectedUser({ ...selectedUser, options: [...selectedUser.options, ""] });
        }
        
    };

    const removeOption = (index) => {
        const newOptions = formData.options.filter((_, i) => i !== index);
      
        setFormData({ ...formData, options: newOptions });
        if(selectedUser &&  selectedUser.length>0){
            const newOptionsEdit = selectedUser.options.filter((_, i) => i !== index);
            setSelectedUser({ ...selectedUser, options: newOptionsEdit });
        }
        
    };

    const handleWallChange = (event) => {
           const value = event.target.value;
           console.log(value)
        setSelectedWall(value);
        if (value === 'match') {
            setFormData(prevFormData => ({ ...prevFormData, location: 'Match Wall' }));
            if(selectedUser &&  selectedUser.length>0){
                setSelectedUser(prevFormData => ({ ...prevFormData, location: 'Match Wall' }));
            }
           
        }
    };

    const handlePlayerChange = (event, newValue) => {

        if (selectedWall === 'player') {
            setFormData(prevFormData => ({ ...prevFormData, location: newValue }));
            setSelectedUser(prevFormData => ({ ...prevFormData, location: newValue }));
        }
    };


    const ActionMenu = (params) => {
        const [anchorEl, setAnchorEl] = useState(null);
    
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };
    
        const handleClose = () => {
            setAnchorEl(null);
        };
    
        const handleEdit = () => {
            handleClose();
            toggleEdit();
            setSelectedUser(params.row);
        };
    
        const handleDelete = () => {
            handleClose();
            // Add your delete logic here, using params.row to get row data
            console.log('Delete', params.row);
            fetch(`${BASE_URL}/polls/${params.row.id}`, {
                method:'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcGkuZGV2Lnh5ei5jb20iLCJhdWQiOiJ4eXouY29tIiwic3ViIjoiMTJiNTBhNDgtNWU5ZS00MzNmLTgyNmMtMTJhNmQ3MmM5N2NkIiwiaWF0IjoxNzExNDU2OTEzLCJleHAiOjE3NDI1NjA5MTMsInBybSI6IjMwZDQyNzg2ZGMyNTU3MDBkZDkzNzViMWQ4ZDYwN2E2MzBhYzViMzJhNWU1MjUxODQ2ZDQyNzE1YjBlN2Y3YTllZjczZjAzZDk1MzI3OGQ5NTdjOTVmOGE2YTVkZjk1MTM3MDc4ZTY4ODNlNTU3MjUzODczZjViYjNjMDZiNzZmIn0.msUlenclhnnuqwdU9R5HL32rYnUVmdyITkPlzSWDcio'
                },
              
            })
                .then(response => response.json())
                .then(data => {
                 fetchMatchData();
                })
                .catch(error => console.error('Error fetching matches for dropdown:', error));
        };
    
        return (
            <div>
                <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleEdit}>Edit</MenuItem>
                    <MenuItem onClick={handleDelete}>Delete</MenuItem>
                </Menu>
            </div>
        );
    };
    return (


        <>

            <div class="card-body">
                <Grid container spacing={2} sx={{ marginBottom: '20px' }}>


                    <Grid item xs={12} sm={12} md={5}>
                        <TextField
                            fullWidth
                            label="Search"
                            variant="outlined"
                            value={searchText}
                            onChange={handleSearch}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={5}>
                        <Autocomplete
                            id="player-name-filter"
                            options={playerNames}
                            value={playerNameFilter}
                            onChange={(event, newValue) => setPlayerNameFilter(newValue)}
                            renderInput={(params) => <TextField {...params} label="Filter by Location" variant="outlined" />}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                        <Button style={{ width: '100%', height: '100%' }} variant="contained" color="primary" onClick={toggle}>
                            Add Polls
                        </Button>
                    </Grid>
                </Grid>
                <DataGrid
                    rows={filteredData}
                    columns={columns}
                    pageSize={25}
                    rowsPerPageOptions={[25, 50, 100]}
                    getRowId={(row) => row.id}
                />
            </div>

            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Add Polls</ModalHeader>
                <ModalBody>
                    <form className="form theme-form">
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Questions"
                                    variant="outlined"
                                    placeholder="Questions"
                                    value={formData.question}
                                    onChange={(event) => handleChange(event, 'question')}
                                />
                            </Grid>
                            {formData.options.map((option, index) => (
                                <Grid item xs={12} key={index} container alignItems="center">
                                    <Grid item xs={10}>
                                        <TextField
                                            fullWidth
                                            label={`Option ${index + 1}`}
                                            variant="outlined"
                                            value={option}
                                            onChange={(event) => handleChange(event, 'options', index)}
                                        />
                                    </Grid>
                                    <Grid item xs={1} style={{ marginLeft: '15px' }}>
                                        <IconButton onClick={() => removeOption(index)} disabled={formData.options.length === 1}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            ))}
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <Button onClick={addOption} variant="contained" color="primary">
                                    Add Option
                                </Button>
                            </Grid>

                            <Grid item xs={12} container justifyContent="center" alignItems="center">
                                <FormControl component="fieldset">
                                    <RadioGroup row aria-label="wall-type" name="wall-type" value={selectedWall} onChange={handleWallChange}>
                                        <FormControlLabel value="match" control={<Radio />} label="Match Wall" />
                                        <FormControlLabel value="player" control={<Radio />} label="Player Wall" />
                                    </RadioGroup>
                                </FormControl>

                                {selectedWall === 'player' && (
                                    <Grid item xs={12}>

                                        <Autocomplete
                                            id="player-name-filter"
                                            options={playerNames}
                                            value={formData.location}
                                            onChange={handlePlayerChange}
                                            renderInput={(params) => <TextField {...params} label="Select Player" variant="outlined" />}
                                            fullWidth
                                        />
                                    </Grid>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    label="Start Time (IST)"
                                    type="datetime-local"
                                    variant="outlined"
                                    fullWidth
                                    value={dateFromFilter}
                                    onChange={(e) => setDateFromFilter(e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ backgroundColor: 'white', borderRadius: '8px' }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    label="End Time (IST)"
                                    type="datetime-local"
                                    variant="outlined"
                                    fullWidth
                                    value={dateToFilter}
                                    onChange={(e) => setDateToFilter(e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ backgroundColor: 'white', borderRadius: '8px' }}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </ModalBody>
                <ModalFooter >
                    <Button color="primary" onClick={handleSubmit}>Submit</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>


            <Modal isOpen={modalEdit} toggle={toggleEdit}>
                <ModalHeader toggle={toggleEdit}>Edit Polls</ModalHeader>
                <ModalBody>
                    <form className="form theme-form">
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Questions"
                                    variant="outlined"
                                    placeholder="Questions"
                                    value={selectedUser.question}
                                    onChange={(event) => handleChangeEdit(event, 'question')}
                                />
                            </Grid>
                            {selectedUser?.options?.map((option, index) => (
                                <Grid item xs={12} key={index} container alignItems="center">
                                    <Grid item xs={10}>
                                        <TextField
                                            fullWidth
                                            label={`Option ${index + 1}`}
                                            variant="outlined"
                                            value={option}
                                            onChange={(event) => handleChangeEdit(event, 'options', index)}
                                        />
                                    </Grid>
                                    <Grid item xs={1} style={{ marginLeft: '15px' }}>
                                        <IconButton onClick={() => removeOption(index)} disabled={formData.options.length === 1}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            ))}
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <Button onClick={addOption} variant="contained" color="primary">
                                    Add Option
                                </Button>
                            </Grid>

                            <Grid item xs={12} container justifyContent="center" alignItems="center">
                                <FormControl component="fieldset">
                                    <RadioGroup row aria-label="wall-type" name="wall-type" value={selectedWall} onChange={handleWallChange}>
                                        <FormControlLabel value="match" control={<Radio />} label="Match Wall" />
                                        <FormControlLabel value="player" control={<Radio />} label="Player Wall" />
                                    </RadioGroup>
                                </FormControl>

                                {selectedWall === 'player' && (
                                    <Grid item xs={12}>

                                        <Autocomplete
                                            id="player-name-filter"
                                            options={playerNames}
                                            value={selectedUser.location}
                                            onChange={handlePlayerChange}
                                            renderInput={(params) => <TextField {...params} label="Select Player" variant="outlined" />}
                                            fullWidth
                                        />
                                    </Grid>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    label="Start Time (IST)"
                                    type="datetime-local"
                                    variant="outlined"
                                    fullWidth
                                    value={dateFromFilter}
                                    onChange={(e) => setDateFromFilter(e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ backgroundColor: 'white', borderRadius: '8px' }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    label="End Time (IST)"
                                    type="datetime-local"
                                    variant="outlined"
                                    fullWidth
                                    value={dateToFilter}
                                    onChange={(e) => setDateToFilter(e.target.value)}
                                    InputLabelProps={{ shrink: true }}
                                    sx={{ backgroundColor: 'white', borderRadius: '8px' }}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleEdit}>Submit</Button>
                    <Button color="secondary" onClick={toggleEdit}>Cancel</Button>
                </ModalFooter>
            </Modal>

        </>


    )
}

export default Polls
