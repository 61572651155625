import { SET_MATCHES, MATCH_LOGOUT} from "./actionTypes";

const initialState = {
  matches: null,

  logout: null
};

const matchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MATCHES:
      return {
        ...state,
        matches: action.payload,
      };

    case MATCH_LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default matchReducer;