import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../components/CommonExport';
import './AddCompetition.css';
import Component from '../../../components/Component';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
const AddCompetition = () => {
    const [competitions, setCompetitions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [status, setStatus] = useState('live');
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState('1');
    const [totalPages, setTotalPages] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogCompetitionId, setDialogCompetitionId] = useState(null);
    const [activePage, setActivePage] = useState('1');
    const [confirmButtonLoading, setConfirmButtonLoading] = useState(false);
    const [addCompetitionSuccess, setAddCompetitionSuccess] = useState(false);
    const [addCompetitionError, setAddCompetitionError] = useState(false);



    useEffect(() => {
        if (status === 'result') {
            setPage(totalPages);
        }
    }
        , [status]);
    useEffect(() => {
        const fetchCompetitions = async () => {
            setLoading(true);
            try {
                console.log(page);
                const response = await axios.get(`https://rest.entitysport.com/v2/competitions?token=1cfc73f9de2f1b2674ac2b8be58eb8aa&per_page=100&paged=${page}&status=${status}`);
                setTotalPages(response.data.response.total_pages);
                const responseData = response.data.response.items
                    .filter((item) => {
                        if (status === 'live') {
                            return true;
                        } else if (status === 'fixture') {
                            return new Date(item.datestart) > new Date();
                        } else {
                            return new Date(item.dateend) < new Date();
                        }
                    })
                    .sort((a, b) => {
                        if (status === 'live') {
                            return new Date(b.datestart) - new Date(a.datestart);
                        } else if (status === 'fixture') {
                            return new Date(a.datestart) - new Date(b.datestart);
                        } else {
                            return new Date(b.dateend) - new Date(a.dateend);
                        }
                    });
                console.log(responseData);
                const competitionsWithId = await Promise.all(responseData.map(async (competition, index) => {
                    const exist = await existInDataBase(competition.cid);
                    return {
                        ...competition,
                        id: index,
                        exist: exist
                    };
                }));

                setCompetitions(competitionsWithId);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchCompetitions();
    }, [status, page]);

    const existInDataBase = async (cid) => {
        try {
            const response = await axios.get(`${BASE_URL}/admin/competitions/get_competition/${cid}`);
            return response.data.data.competition ? true : false;
        } catch (error) {
            console.error('Error checking competition existence:', error);
            return false;
        }
    };

    const AddCompetitionDataToDataBase = async (cid) => {
        try {
            const response = await axios.post(`${BASE_URL}/competitions/add-competition-data/${cid}`);
            console.log(response.data.data)
            return response.data.data.competition ? true : false;
        } catch (error) {
            console.error('Error checking competition existence:', error);
            return false;
        }
    };

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filterCompetitionsByDate = (competition) => {
        if (!startDate && !endDate) return true;

        const createdAt = new Date(competition.datestart);
        const start = startDate ? new Date(startDate) : null;
        const end = endDate ? new Date(endDate) : null;

        if (start && end) {
            return createdAt >= start && createdAt <= end;
        } else if (start) {
            return createdAt >= start;
        } else if (end) {
            return createdAt <= end;
        }

        return true;
    };
    const handleConfirmAction = async () => {
        setConfirmButtonLoading(true);
        const res = await AddCompetitionDataToDataBase(dialogCompetitionId);

        setDialogOpen(false);
        setConfirmButtonLoading(false);
        setDialogCompetitionId(null);
        window.location.reload();
    };
    const handleCloseDialog = () => {
        setDialogOpen(false);
        setDialogCompetitionId(null);
    };
    const handleAddCompetitionClick = (cid) => {
        console.log('this is cid', cid)
        setDialogCompetitionId(cid);
        setDialogOpen(true);
    }
    const filterCompetitionsByName = (competition) => {
        return competition.title.toLowerCase().includes(searchTerm.toLowerCase());
    };

    const filteredCompetitions = competitions
        .filter(filterCompetitionsByDate)
        .filter(filterCompetitionsByName);

    const ToggleButton = () => {
        const statusEnum = ['live', 'fixture', 'result'];

        const handleToggle = async () => {
            const newStatus = statusEnum[(statusEnum.indexOf(status) + 1) % statusEnum.length]
            const response = await axios.get(`https://rest.entitysport.com/v2/competitions?token=1cfc73f9de2f1b2674ac2b8be58eb8aa&per_page=100&paged=${page}&status=${newStatus}`);
            const retievedPages = response.data.response.total_pages;
            setTotalPages(parseInt(retievedPages))
            if (newStatus == 'result') {
                setPage(totalPages)
            }
            else {
                setPage('1');
            }
            setStatus(newStatus);

        }

        return (
            <button className="toggle-button" onClick={handleToggle}>
                {status === 'live' ? 'Live' : status === 'fixture' ? 'Upcoming' : 'Completed'}
            </button>
        );
    };

    return (
        <Component>
            <div className="page-body">
                <div className="container-fluid">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="d-flex align-items-center">
                                <IconButton style={{ background: '#FFFFFF' }} component={Link} to="/competitions">
                    <ArrowBackIcon />
                  </IconButton>
                                    <h3 style={{ marginLeft: '20px' }}>Add Competitions</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                <div className="add-competition-container">
            <ToggleButton />
            <div className="date-filters">
                <div>
                <label>Start Date: </label>
                <input type="date" value={startDate} onChange={handleStartDateChange} />

                </div>
                <div>
                <label>End Date: </label>
                <input type="date" value={endDate} onChange={handleEndDateChange} />

                </div>
                
                
            </div>
            <div className="search-bar-container" style={{ marginBottom: '10px' }}>
                <input style={{ width: '100%', borderRadius: '10px', padding: '5px', border: '1px solid #ccc' }}
                    type="text"
                    placeholder="Search competitions..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="search-bar"
                />
            </div>
            {loading ? (
                <p className="loading-message">Loading competitions...</p>
            ) : error ? (
                <p className="error-message">Error loading competitions: {error.message}</p>
            ) : (
                <ul className="competition-list">
                    {filteredCompetitions.map((competition) => (
                        <li
                            key={competition.id}
                            className="competition-item"
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: '10px',
                                padding: '10px',
                                border: '1px solid #ddd',
                                borderRadius: '5px',
                                backgroundColor: '#f9f9f9'
                            }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <span style={{ fontWeight: 'bold' }}>{competition.title}</span>
                                {competition.exist && <span style={{ fontFamily: 'cursive', color: 'green' }}> (exists in database ✅) </span>}
                                <span style={{ fontSize: '12px' }}>Date Start: {competition.datestart}</span>
                                <span style={{ fontSize: '12px' }}>Date End: {competition.dateend}</span>
                            </div>
                            <button
                                style={{
                                    backgroundColor: !competition.exist ? 'green' : 'grey',
                                    color: 'white',
                                    borderRadius: '10px',
                                    padding: '5px 15px',
                                    cursor: !competition.exist ? 'pointer' : 'not-allowed',
                                    border: 'none'
                                }}
                                onClick={() => handleAddCompetitionClick(competition.cid)}
                                className="add-competition-button"
                                disabled={competition.exist}
                            >
                                {competition.exist ? 'Added' : 'Add'}
                            </button>
                        </li>
                    ))}
                </ul>

            )}
            <div className="pagination">
                {status === 'live' || status === 'fixture' ? [...Array(totalPages)].map((_, i) => (
                    <button
                        key={i}
                        onClick={() => setPage(i + 1)}
                        className={page == i + 1 ? 'active' : 'not-active'}
                    >
                        {i + 1}
                    </button>
                )) : [...Array(totalPages)].map((_, i) => (
                    <button
                        key={i}
                        onClick={() => {
                            console.log('totalPages toggle button', totalPages)
                            setPage(totalPages - i)
                            setActivePage(i + 1)
                        }}
                        className={activePage == i + 1 ? 'active' : 'not-active'}
                    >
                        {i + 1}
                    </button>))}
            </div>
            <Dialog
                open={dialogOpen}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Confirm Adding Competition
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        'Are you sure you want to add this competition?'
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary" disabled={confirmButtonLoading}>
                        No
                    </Button>
                    <Button onClick={handleConfirmAction} color="primary" autoFocus>
                        {confirmButtonLoading ? 'Adding...' : 'Yes'}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Component>
        
    );
}

export default AddCompetition;

