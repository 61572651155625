import react from "react";
import Component from "../../components/Component";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
const competitionsSettings = () => {
    return (
        <Component>
            <div className="page-body">
                <div className="container-fluid">
                    <div className="page-header">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="d-flex align-items-center">
                                    <IconButton style={{ background: '#FFFFFF' }} component={Link} to="/">
                                        <ArrowBackIcon />
                                    </IconButton>
                                    <h3 style={{ marginLeft: '20px' }}>Manage Competitions</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-body">
                                    <div style={{ fontSize: '15px', display: 'flex', gap: '20px', alignItems: 'center' }}>
                                        <Link
                                            style={{
                                                backgroundColor: 'yellowGreen',
                                                padding: '10px',
                                                borderRadius: '10px',
                                                textDecoration: 'none',
                                                color: 'inherit'
                                            }}
                                            to="/competitions/edit"
                                        >
                                            Edit Competitions
                                        </Link>
                                        <Link
                                            style={{
                                                backgroundColor: 'YellowGreen',
                                                padding: '10px',
                                                borderRadius: '10px',
                                                textDecoration: 'none',
                                                color: 'inherit'
                                            }}
                                            to="/competitions/add"
                                        >
                                            Add Competitions
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Component>
    );




}
export default competitionsSettings;