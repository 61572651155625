import React from 'react';
import { useLocation } from 'react-router-dom';
const sidebarItems = [
//   {
//     icon: 'database',
//     title: 'Data Table',
//     link: '/dashboard',
//   },
{
  icon: 'layout',
  title: 'Dashboard',
  link: '/',
},
  {
    icon: 'layout',
    title: 'User',
    link: '/user-dashboard',
  },
  {
    icon: 'layout',
    title: 'Player',
    link: '/player-dashboard',
  },
  {
    icon: 'layout',
    title: 'Match',
    link: '/match-dashboard',
  },
  {
    icon: 'layout',
    title: 'Post Highlights',
    link: '/highlights',
  },
  {
    icon: 'layout',
    title: 'Player Photo',
    link: '/player-photo',
  },

];


function SideBar() {
  const location = useLocation();
  return (
    <div>
      <header className="main-nav">
        <nav>
          <div className="main-navbar">
            <div className="left-arrow" id="left-arrow"><i data-feather="arrow-left"></i></div>
            <div id="mainnav">    
              <ul className="nav-menu custom-scrollbar">
                <li className="back-btn">
                  <div className="mobile-back text-end"><span>Back</span><i className="fa fa-angle-right ps-2" aria-hidden="true"></i></div>
                </li>
                <li className="sidebar-main-title">
                  <div>
                    <h6>Dashboard</h6>
                  </div>
                </li>
                {sidebarItems.map((item, index) => (
        <li key={index}>
          <a
            className={`nav-link menu-title link-nav ${location.pathname === item.link ? 'active' : ''}`}
            href={item.link}
          >
            <i data-feather={item.icon}></i>
            <span>{item.title}</span>
          </a>
        </li>
      ))}
              </ul>
            </div>
            <div className="right-arrow" id="right-arrow"><i data-feather="arrow-right"></i></div>
          </div>
        </nav>
      </header>
    </div>
  );
}

export default SideBar;
