import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { BASE_URL } from '../../../components/CommonExport';
import Component from '../../../components/Component';
import { Box, Typography, CircularProgress, Paper, Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import './editTeams.css';

const CompetitionTeams = () => {
    const [teams, setTeams] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [open, setOpen] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [newColor, setNewColor] = useState('');

    useEffect(() => {
        const getTeams = async () => {
            setLoading(true);
            try {
                const compId = window.location.pathname.split('/').pop('3');
                console.log(compId);
                const response = await axios.get(`${BASE_URL}/admin/get-teams/${compId}`);
                setTeams(response.data.data.teams);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        getTeams();
    }, []);

    const handleOpenDialog = (team) => {
        setSelectedTeam(team);
        setNewColor(team.team_colour); // Set initial color value
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
        setSelectedTeam(null);
    };

    const handleColorChange = async () => {
        if (selectedTeam) {
            try {
                await axios.put(`${BASE_URL}/admin/update-team/${selectedTeam.tid}`, { color: newColor });

                setTeams((prevTeams) =>
                    prevTeams.map((team) =>
                        team.id === selectedTeam.id ? { ...team, team_colour: newColor } : team
                    )
                );

                handleCloseDialog();
            } catch (error) {
                setError(error);
            }
        }
    };

    const columns = [
        { field: 'title', headerName: 'Title', width: 200 },
        { field: 'type', headerName: 'Type', width: 150 },
        {
            field: 'logo_url',
            headerName: 'Logo',
            width: 150,
            renderCell: (params) => (
                <img src={params.value} alt="Logo" style={{ width: '50px', height: '50px' }} />
            ),
        },
        {
            field: 'team_colour',
            headerName: 'Team Colour',
            width: 150,
            renderCell: (params) => (
                <div style={{ backgroundColor: params.value, width: '100%', height: '100%' }} />
            ),
        },
        {
            field: 'edit',
            headerName: 'Edit',
            width: 150,
            renderCell: (params) => (
                <Button variant="outlined" onClick={() => handleOpenDialog(params.row)}>
                    Edit Color
                </Button>
            ),
        },
    ];

    return (
        <Component>
    <div className="page-body">
        <div className="container-fluid">
            <div className="page-header">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="d-flex align-items-center">
                            <IconButton style={{ background: '#FFFFFF' }} component={Link} to="/competitions/edit">
                                <ArrowBackIcon />
                            </IconButton>
                            <h3 style={{ marginLeft: '20px' }}>Edit teams</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-body">
                        <Box className="competition-teams-container">
            <Paper elevation={3} className="competition-teams-paper">
                {error && <Typography color="error">Error: {error.message}</Typography>}
                {loading ? (
                    <Box className="competition-teams-loading">
                        <CircularProgress />
                    </Box>
                ) : (
                    <DataGrid
                        rows={teams}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10, 25, 50]}
                        autoHeight
                        disableSelectionOnClick
                        getRowId={(row) => row.id}
                        className="competition-teams-data-grid"
                    />
                )}
            </Paper>

            <Dialog open={open} onClose={handleCloseDialog}>
                <DialogTitle>Edit Team Color</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        label="Team Color"
                        value={newColor}
                        onChange={(e) => setNewColor(e.target.value)}
                        margin="dense"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleColorChange} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
                            

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</Component>
    );
};

export default CompetitionTeams;

