import React from 'react'
import NavBar from './NavBar/NavBar'
import SideBar from './SideBar/SideBar'
import Footer from './Footer/Footer'

function Component({ children }) {
    return (
        <div>
            {/* <div class="loader-wrapper">
      <div class="theme-loader">    
        <div class="loader-p"></div>
      </div>
    </div> */}
            <div class="page-wrapper null compact-wrapper" id="pageWrapper">
                <NavBar />
                <div class="page-body-wrapper null">
                    <SideBar />
                    {children}
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Component
