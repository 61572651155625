import React from 'react';
import './noAccess.css'; // Import the CSS file for styles

function NoAccess() {
  return (
    <div className="no-access-container">
      <div className="no-access-content">
        <h1>Access Denied</h1>
        <p>Sorry, you do not have the necessary permissions to view this page.</p>
        <button type="button" onClick={() => window.location.href = '/'}>Go Back</button>
      </div>
    </div>
  );
}

export default NoAccess;