import React, { useState } from 'react';
import './addRole.css'; // Assuming you have a CSS file for styling

const AddRoleForm = ({ onAddRole }) => {
  const [permission, setPermission] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    await onAddRole({ permission });
    setPermission('');
  };

  return (
    <form className="add-role-form" onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="permission">Permission:</label>
        <input
          type="text"
          id="permission"
          className="form-control"
          value={permission}
          onChange={(e) => setPermission(e.target.value)}
          required
        />
      </div>
      <button type="submit" className="btn btn-primary">Add Role</button>
    </form>
  );
};

export default AddRoleForm;
