import React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";
import { publicRoutes, authProtectedRoutes } from "./Routes";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setUserId, setUserRole, setUserName, setJwtToken, setU_photo, setPermissions, setIsSuperAdmin } from './Store/Role/action';
import fetchData from "./components/authenticate";
import { useEffect } from "react";
import './index.css';

function RoutesHandler() {
  const location = useLocation();
  const sessionToken = sessionStorage.getItem("sessionToken");
  const userId = useSelector((state) => state.roleReducer.userId);
  const userPermissions = useSelector((state) => state.roleReducer.permissions);
  const superAdmin = useSelector((state) => state.roleReducer.isSuperAdmin);
  const dispatch = useDispatch();

  useEffect(() => {
    // Only call fetchData if not on the "/login" page
    if (location.pathname !== "/login") {
      const authenticateUser = async () => {
        try {
          const response = await fetchData();
          if (response) {
            console.log("User is authenticated");
            dispatch(setUserId(response.data.data.id));
            dispatch(setUserRole(response.data.data.role_id));
            dispatch(setUserName(response.data.data.name));
            dispatch(setPermissions(response.data.data.permissions));
            dispatch(setIsSuperAdmin(response.data.data.superAdmin));
          } else {
            alert("User is not authenticated");
            window.location = "/login";
          }
        } catch (error) {
          console.error("Error during authentication:", error);
          window.location = "/login"; // Redirect to login in case of error
        }
      };

      authenticateUser();
    }
  }, [location.pathname, dispatch]);

  return (
    <Routes>
      {publicRoutes.map((route, idx) => (
        <Route key={idx} path={route.path} element={<route.component />} />
      ))}

      {authProtectedRoutes.map((route, idx) => (
        <Route
          key={idx}
          path={route.path}
          element={
            superAdmin || (userId && userPermissions.includes(route.id.toString())) ? (
              <route.component />
            ) : (
              <Navigate to="/access-denied" />
            )
          }
        />
      ))}
    </Routes>
  );
}
function App() {
  return (
    <Router>
      <RoutesHandler />
    </Router>
  );
}

export default App;
